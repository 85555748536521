import React from 'react';
import {useTranslation} from "react-i18next";
import Filters from "../Filters";
import {useCreateFiltersByProperty} from "../../hooks";
import {MapLayoutGrid} from "../index";

const   PropertySettings = props => {
    const name = 'PropertySettings';
    const { isEdit } = props;
    const nameForm = `properties_${isEdit ? 'edit' : 'create'}`;
    const {t} = useTranslation();
    const filters = useCreateFiltersByProperty({ types: ['city', 'zip', 'warning'], nameForm}) || {};
    const reduceFunc = (obj, name) => obj.reduce((acc, c) => ([...acc, { value: c[name], label: c[name], count: c.count}]), []);

    const filterList = [
        {
            defaultValue: t(`Filters.city`),
            value: 'city',
            options: filters.city ? reduceFunc(filters.city, 'city') : [],
            id: 1,
            type: 'selector',
            show: false
        },
        {
            defaultValue: t(`Filters.zip`),
            value: 'zip',
            options: filters.zip ? reduceFunc(filters.zip, 'zip') : [],
            id: 2,
            type: 'selector',
            show: false
        }
    ];

    return (
        <div>
            <div className={'flex-row flex-between mb-70'}>
                <h2>{t(`${name}.header`)}</h2>
            </div>
            <Filters className={'mb-70'} filterList={filterList} nameForm={nameForm} showFilterIcon={false} />
            <MapLayoutGrid nameForm={nameForm} withChoice isEdit={isEdit} />
        </div>
    )
}

export default PropertySettings;