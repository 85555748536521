export const REMOVE_AUTH = 'REMOVE_AUTH';

export const SIGN_IN = 'SIGN_IN';
export const SIGN_IN_SUCCEEDED = 'SIGN_IN_SUCCEEDED';
export const SIGN_IN_FAILED = 'SIGN_IN_FAILED';

export const SIGN_OUT = 'SIGN_OUT';

export const CHECK_CONNECTION = 'CHECK_CONNECTION';
export const CHECK_CONNECTION_FAILED = 'CHECK_CONNECTION_FAILED';

export const PROFILE_IMAGE = 'PROFILE_IMAGE';

export const GET_PROFILE_INFO = 'GET_PROFILE_INFO';
export const GET_PROFILE_INFO_SUCCEEDED = 'GET_PROFILE_INFO_SUCCEEDED';
export const GET_PROFILE_INFO_FAILED = 'GET_PROFILE_INFO_FAILED';

export const UPDATE_PROFILE_INFO = 'UPDATE_PROFILE_INFO';
export const UPDATE_PROFILE_INFO_SUCCEEDED = 'UPDATE_PROFILE_INFO_SUCCEEDED';
export const UPDATE_PROFILE_INFO_FAILED = 'UPDATE_PROFILE_INFO_FAILED';