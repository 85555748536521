import {createStore, combineReducers, applyMiddleware} from 'redux';
import createSagaMiddleware from 'redux-saga'
import { user, performance, properties, img360, imgSupport, map, userAdministration } from './reducers';
import appSagas from './sagas'

const rootReducer = combineReducers({
  user,
  userAdministration,
  performance,
  properties,
  img360,
  imgSupport,
  map,
});

const sagaMiddleware = createSagaMiddleware();

const store = createStore(rootReducer, applyMiddleware(sagaMiddleware));

sagaMiddleware.run(appSagas);

export default store;
