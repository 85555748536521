import React from 'react';
import { styled } from '@mui/material/styles';
import {Switch} from "@mui/material";

const IOSSwitch = styled((props) => (
        <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
    ))(({ theme }) => ({
        width: 64,
        height: 33,
        padding: 0,
        '& .MuiSwitch-switchBase': {
            padding: 0,
            margin: 4,
            transitionDuration: '300ms',
            '&.Mui-checked': {
                transform: 'translateX(30px)',
                color: '#fff',
                '& + .MuiSwitch-track': {
                    backgroundColor: theme.palette.mode === 'dark' ? '#00205B' : '#00205B',
                    opacity: 1,
                    border: 0,
                },
                '&.Mui-disabled + .MuiSwitch-track': {
                    opacity: 0.5,
                },
                '& .MuiSwitch-thumb': {
                    background: '#F6F8FC',
                },
            },
            '&.Mui-focusVisible .MuiSwitch-thumb': {
                color: '#00205B',
                border: '6px solid #fff',
            },
            '&.Mui-disabled .MuiSwitch-thumb': {
                color:
                    theme.palette.mode === 'light'
                        ? theme.palette.grey[100]
                        : theme.palette.grey[600],
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
            },
        },
        '& .MuiSwitch-thumb': {
            boxSizing: 'border-box',
            width: 25,
            height: 25,
            boxShadow: 'none',
            background: '#A5A5BA',
        },
        '& .MuiSwitch-track': {
            borderRadius: 50,
            backgroundColor: theme.palette.mode === 'light' ? '#F6F8FC' : '#39393D',
            border: '1px solid',
            borderColor: '#A5A5BA',
            opacity: 1,
            transition: theme.transitions.create(['background-color'], {
                duration: 500,
            }),
        },
    }));

export default IOSSwitch;