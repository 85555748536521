import React from 'react';
import ReactDOM from 'react-dom'
import App from './App';
import reportWebVitals from './reportWebVitals';
import {SocketContext, socket} from './utils/socket-config';
import {Provider} from "react-redux";
import store from "./redux";

ReactDOM.render(
  // <React.StrictMode>
    <SocketContext.Provider value={socket}>
      <Provider store={store}>
       <App />
      </Provider>
    </SocketContext.Provider>,
  // </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
