import React from "react";
import socketio from "socket.io-client";
import { urlBack } from "./API";
import {eventChannel} from "redux-saga";

export let socket;
export let SocketContext = React.createContext(socket);

export const socketEmit = (method, payload) => socket.emit(method, payload);
export const socketOn = (method, cb) => socket.on(method, cb);

export const connectSocket = () => {
  console.log('Connecting socket...');
  socket = socketio.connect(urlBack);
  SocketContext = React.createContext(socket);
  return socket;
};

export const disconnectSocket = () => {
  console.log('Disconnecting socket...');
  socket?.disconnect();
};

export const socketEmitter = ({emit, on, payload}) => {
  return eventChannel(emitter => {
    // socket.emit('ping', subject)
    socketEmit(emit, payload);
    socketOn(on, resp => {
      emitter(resp)
    });
    // const newChannel = channel(subject)
    //
    // newChannel.on('tweets', tweets => {
    //   emitter(tweets)
    // })
    return () => {
      disconnectSocket();
      // newChannel.removeAllListeners('tweets')
      // socket.emit('untrack', subject)
    }
  })
}