import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import styles from '../../styles/components/Calendar.module.css';
import { styled } from '@mui/material/styles';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {Box, Button} from "@mui/material";
import moment from "moment";
import {PickersDay} from "@mui/x-date-pickers";
import ArrowBackIosRoundedIcon from '@mui/icons-material/ArrowBackIosRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';

import {
    getDateFormat,
    getFromDay,
    getFrom7Day,
    getFromMonth,
    getFromYear,
    getToDay,
    getTo7Day,
    getToMonth,
    getToYear,
    getStartDay,
    getEndDay,
    isSameCurrentDateByDay,
    getFromStartMonth,
    getFromStartYear,
    getEndMonth,
    getTo14Day
} from "../../utils/functions";
import {useTranslation} from "react-i18next";

const onViewChange = v => {
    switch (v) {
        case 'day': return 'month'
        case 'month': return 'year'
        case 'year': return 'year'
        default: break;
    }
}
const setView = v => {
    switch (v) {
        case 'day': return 'day'
        case 'week': return 'day'
        case 'month': return 'day'
        case 'year': return 'year'
        case 'max': return 'year'
        default: return 'year';
    }
}

const CustomPickersDay = styled(PickersDay, { shouldForwardProp: (prop) =>
        prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
    })(({ theme, dayIsBetween, isFirstDay, isLastDay, isSelectDay }) => {
        return {
        ...((dayIsBetween || isSelectDay) && {
            borderRadius: 0,
            backgroundColor: `${theme.palette.primary.main} !important`,
            color: `${theme.palette.common.white} !important`,
            '&:hover, &:focus': {
                backgroundColor: theme.palette.primary.dark,
            },
        }),
        ...((isFirstDay || isSelectDay) && {
            borderTopLeftRadius: '50%',
            borderBottomLeftRadius: '50%',
        }),
        ...((isLastDay || isSelectDay) && {
            borderTopRightRadius: '50%',
            borderBottomRightRadius: '50%',
        }),
        ...((isLastDay) && {
            borderTopLeftRadius: '0',
            borderBottomLeftRadius: '0',
        }),
    }
});

const Calendar = props => {
    const {
        minDate = null,
        maxDate = new Date(),
        className = '',
        viewChange,
        dateFrom = null,
        dateTo = null,
        setFrom,
        setTo,
        openTo = 'year'
    } = props;
    const [date, setDate] = useState(moment());
    const [fromDay, setFromDay] = useState();
    const [toDay, setToDay] = useState();
    const [disabledNext, setDisabledNext] = useState(false);
    const [disabledPrev, setDisabledPrev] = useState(false);
    const [isLocalSet, setIsLocalSet] = useState(false);
    const [viewFromCalendar, setViewFromCalendar] = useState(false);
    const { i18n, t } = useTranslation();

    useEffect(() => {
        if (isLocalSet) setTimeout(() => setIsLocalSet(false), 1000);
    }, [isLocalSet])

    let setTime = useRef(null);
    useEffect(() => {
        if (fromDay && toDay) {
            setIsLocalSet(true)
            if (setTime.current) clearTimeout(setTime.current);
            setTime.current = setTimeout(() => {
                setFrom(fromDay)
                setTo(toDay)
            }, 10)
        }
    }, [getDateFormat(fromDay), getDateFormat(toDay)])

    let setPropTime = useRef(null);
    useEffect(() => {
        if (!isLocalSet && dateFrom && dateTo && (getDateFormat(fromDay) !== getDateFormat(dateFrom) || getDateFormat(toDay) !== getDateFormat(dateTo))) {
            if (setPropTime.current) clearTimeout(setPropTime.current);
            setPropTime.current = setTimeout(() => {
                setFromDay(dateFrom)
                setToDay(dateTo)
            }, 10)
        }
    }, [getDateFormat(dateFrom), getDateFormat(dateTo)])

    useEffect(() => {
        if (openTo && viewFromCalendar) {
            switch (openTo) {
                case 'day': setFromDay(getFromDay(toDay)); break;
                case 'week': setFromDay(getFrom7Day(toDay)); break;
                case 'month': setFromDay(getFromStartMonth(toDay)); break;
                case 'year': setFromDay(getFromStartYear(toDay)); break
                case 'max': setFromDay(getFromYear(toDay)); break;
                default: break;
            }
            setViewFromCalendar(false);
        }
    }, [openTo, viewFromCalendar, toDay])

    useEffect(() => {
        if(isSameCurrentDateByDay(toDay)) {
            setDisabledNext(true);
        } else setDisabledNext(false);
        if((getFrom7Day(toDay).isAfter(moment(minDate)) && moment(fromDay).isoWeekday() !== 1)) {
            setDisabledPrev(false)
        }
        if(openTo === 'year' && getFromYear(toDay).isBefore(moment(minDate))){
            setDisabledPrev(true)
        } else if(openTo === 'month' && getFromMonth(toDay).isBefore(moment(minDate))) {
            setDisabledPrev(true)
        }
    }, [toDay, openTo])

    console.log(minDate)

    const nextStep = useCallback(({ if_rule, t: to, f_false }) => {
        if (if_rule) {
            setFromDay(moment(minDate));
            setToDay(to);
            setDate(to);
            setDisabledPrev(true);
        } else {
            setFromDay(f_false);
            setToDay(fromDay);
            setDate(fromDay);
            setDisabledNext(false);
        }
    }, [fromDay, minDate]);

    const prevDate = useCallback(() => {
        switch (openTo) {
            case 'day':
                nextStep({
                    if_rule: getFromDay(toDay).isBefore(moment(minDate), 'day'),
                    t: getToDay(minDate),
                    f_false: getFromDay(fromDay)
                })
                break;
            case 'week':
                if (getFrom7Day(toDay).isAfter(moment(minDate)) && moment(fromDay).isoWeekday() !== 1) {
                    setFromDay(getFrom7Day(moment(fromDay).weekday(1)));
                    setToDay(getFrom7Day(moment(fromDay).weekday(7)));
                    setDisabledPrev(true);
                } else nextStep({
                    if_rule: getFrom7Day(toDay).isBefore(moment(minDate), 'day'),
                    t: getTo7Day(minDate),
                    f_false: getFrom7Day(fromDay)
                })
                break;
            case 'month':
                nextStep({
                    if_rule: getFromMonth(toDay).isBefore(moment(minDate), 'day'),
                    t: getToMonth(minDate),
                    f_false: getFromMonth(fromDay)
                })
                break;
            case 'year':
                    nextStep({
                        if_rule: getFromYear(toDay).isBefore(moment(minDate), 'day'),
                        t: getToYear(minDate),
                        f_false: getFromYear(fromDay)
                    })
                break;
            default: break;
        }
    }, [fromDay]);

    const prevStep = useCallback(({ if_rule, f, t_false }) => {
        if (if_rule) {
            setFromDay(f);
            setToDay(moment());
            setDisabledNext(true);
        } else {
            setFromDay(toDay);
            setToDay(t_false);
            setDate(t_false);
            setDisabledPrev(false);
        }
    }, [toDay])

    const nextDate = useCallback(() => {
        switch (openTo) {
            case 'day':
                prevStep({
                    if_rule: getToDay(toDay).isAfter(moment(), 'day'),
                    f: getFromDay(),
                    t_false: getToDay(toDay)
                })
                break;
            case 'week':
                prevStep({
                    if_rule: getTo7Day(toDay).isAfter(moment(), 'day'),
                    f: getFrom7Day(),
                    t_false: getTo7Day(toDay)
                })
                break;
            case 'month':
                prevStep({
                    if_rule: getToMonth(toDay).isAfter(moment(), 'day'),
                    f: getFromMonth(),
                    t_false: getToMonth(toDay)
                })
                break;
            case 'year':
                prevStep({
                    if_rule: getToYear(toDay).isAfter(moment(), 'day'),
                    f: getFromYear(),
                    t_false: getToYear(toDay)
                })
                break;
            default: break;
        }
    }, [toDay, openTo, prevStep]);

    const renderDay = useCallback((d, selectedDate, dayInCurrentMonth) => {
        const isFirstDay = fromDay && openTo === 'week' && moment(d).isSame(moment(fromDay), 'day');
        const isLastDay = toDay && openTo === 'week' && moment(d).isSame(moment(toDay), 'day');
        const isSelectDay = toDay && openTo === 'day' && moment(d).isSame(moment(toDay), 'day');
        const dayIsBetween = fromDay && toDay && openTo === 'week' && moment(d).isBetween(getStartDay(fromDay), getStartDay(toDay), null, '[]');
       
        return (
            <div key={d}>
                {moment(d).isoWeekday() === 1 &&
                    <Button
                        className={styles.week}
                        variant="text"
                        onClick={() => {
                            viewChange('week')
                            setFromDay(moment(d));
                            setToDay(moment(d).add(6, 'days'));
                        }}
                    >
                        {moment(d).isoWeek()}
                    </Button>
                }
                <CustomPickersDay
                    {...dayInCurrentMonth}
                    disableMargin
                    dayIsBetween={dayIsBetween}
                    isFirstDay={isFirstDay}
                    isLastDay={isLastDay}
                    isSelectDay={isSelectDay}
                    id={isSelectDay || dayIsBetween || isLastDay ? styles.customPicker : ''}
                    onClick={() => viewChange('day')}
                >
                    {moment(d).date()}
                </CustomPickersDay>
            </div>
        );
    }, [fromDay, toDay, openTo, viewChange]);

    const getButtonDate = useCallback((openTo, fromDay, toDay) => {
        if (fromDay && toDay) {
            switch (openTo) {
                case 'day':
                    return getDateFormat(toDay, 'dd DD MMM')
                case 'week':
                    return `${getDateFormat(fromDay, 'dd DD MMM')} - ${getDateFormat(toDay, 'dd DD MMM')}`
                case 'month':
                    const isSame = fromDay.month() === toDay.month();
                    const isCurrent = isSameCurrentDateByDay(toDay);
                    return isSame
                        ? getDateFormat(toDay, 'MMM YYYY')
                        : `${getDateFormat(fromDay, 'DD MMM')} - ${getDateFormat(isCurrent ? getTo14Day(toDay) : toDay, 'DD MMM YYYY')}`
                case 'year':
                    return `${fromDay.year() !== toDay.year() ? `${getDateFormat(fromDay, 'YYYY')} - ` : ''}${getDateFormat(toDay, 'YYYY')}`
                case 'max':
                    return `${getDateFormat(fromDay, 'YYYY')} - ${getDateFormat(toDay, 'YYYY')}}`
                default:
                    break;
            }
        } return getDateFormat();
    }, [t]);

    const renderInput = useCallback(({ inputRef, inputProps, InputProps }) => (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Button className={styles.arrow} variant="text" disabled={disabledPrev} onClick={prevDate}>
                <ArrowBackIosRoundedIcon />
            </Button>
            <Button
                ref={inputRef}
                variant="text"
                className={styles.date_box}
                {...inputProps}
                onClick={() => {
                    const { onClick } = InputProps?.endAdornment.props.children.props;
                    if (onClick) onClick(true)
                }}
            >
                {getButtonDate(openTo, fromDay, toDay)}
            </Button>
            <Button className={styles.arrow} variant="text" disabled={disabledNext} onClick={nextDate}>
                <ArrowForwardIosRoundedIcon />
            </Button>
        </Box>
    ), [t, openTo, fromDay, toDay, disabledPrev, disabledNext, prevDate, nextDate])

    return (
        <div className={`${styles.calendar} ${className}`}>
            <LocalizationProvider dateAdapter={AdapterMoment} locale={i18n.resolvedLanguage}>
                <DatePicker
                    openTo={setView(openTo)}
                    calendarStartDay={1}
                    mask={''}
                    views={['year', 'month', 'day']}
                    label="Year, month and date"
                    value={date}
                    onChange={(newValue) => {
                        if (!moment(newValue).isSame(date, 'day')) {
                            if (openTo === 'day' || openTo === 'week' || openTo === 'month') {
                                setFromDay(getStartDay(newValue));
                                setToDay(getEndDay(newValue));
                                setDate(newValue)
                            } else if (openTo === 'year') {
                                setFromDay(getStartDay(newValue));
                                setToDay(getEndMonth(newValue));
                            } else setToDay(getStartDay(newValue));
                        }
                    }}
                    onViewChange={v => {
                        const view = onViewChange(v);
                        setViewFromCalendar(true);
                        viewChange(view)
                    }}
                    renderInput={renderInput}
                    minDate={moment(minDate)}
                    maxDate={moment(maxDate)}
                    renderDay={renderDay}
                />
            </LocalizationProvider>
        </div>
    )
}

export default Calendar;
