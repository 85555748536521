import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styles from '../../styles/components/DetailsCard/DetailsCharts.module.css'
import {Charts, InformationTopPanel, InformationBottomPanel, CalendarWithTabsWrap} from "../index";
import {useDispatch, useSelector} from "react-redux";
import {setActiveChartData} from "../../redux/actions";
import {selectChartData} from "../../redux/selectors";
import moment from "moment";
import {useTranslation} from "react-i18next";
import {getKeyByValue, isSameCurrentDateByDay} from "../../utils/functions";
import {useGetDataForChart} from "../../hooks";

const isLastDate = (d, item, i) => i === item.length - 1 && moment(d.date).minutes();

const DetailsCharts = props => {
    const name = 'DetailsCharts';
    const {
        card = null, type = '', rules: temperatureRules,
        dateType, from, to, rangeType,
        setDateType, setFrom, setTo, setRangeType,
    } = props;
    const { modbus } = card || {};
    const [data, setData] = useState([]);
    const [rules, setRules] = useState([]);
    const dispatch = useDispatch();
    const chartData = useSelector(selectChartData)
    const { t } = useTranslation();
    const chartId = `chart_${card.id}_${type}`
    useGetDataForChart({ rangeType, from, to, modbus, type, chartId, dateType });

    useEffect(() => {
        const modbusList = (chartData[type][chartId] || []).reduce((acc, item) => [...acc, ...Object.keys(item.modbuses || {})], []);
        const dataByType = chartData[type][chartId]
            ? chartData[type][chartId].reduce((acc, item) => [...acc, ...Object.values(item.modbuses || {})], [])
            : [];
        setData(dataByType.reduce((acc, item, i) => {
            if (!item || modbusList[i] === 'undefined') return acc;
            const iFirstData = Object.values(item).findIndex(v => v.temperature)
            const isSingle = typeof modbus === 'number';
            const isSensorIn = !isSingle && getKeyByValue(modbus, +modbusList[i]) === 'sensor_in';
            return [...acc, {
                id: modbusList[i],
                color: isSingle || isSensorIn ? '#D02B20' : '#2D78C6',
                title: isSingle ? t(`${name}.temperature`) : isSensorIn ? t(`${name}.fastForward`) : t(`${name}.Rewind`),
                data: Object.values(item).reduce(
                    (a, d, index) => (
                        iFirstData && index < iFirstData
                            ? a
                            : [...a, {
                                x: (isLastDate(d, item, index) ? moment(d.date).add(1, rangeType === 1 ? 'hours' : 'day') : moment(d.date)).format('YYYY-MM-DD HH:mm').toString(),
                                y: d.temperature ? +d.temperature.toFixed(2) : null
                            }]
                    ), [])
            }]
        }, []))
    }, [chartData, modbus, rangeType, t, type, chartId])

    useEffect(() => {
        if (temperatureRules) {
            const rulesList = temperatureRules
                .map(r => r.rule.blue
                    .reduce((acc, v) => ({min: acc.min && acc.min < v.temperature[0] ? acc.min : v.temperature[0],
                        max: acc.max && acc.max > v.temperature[1] ? acc.min : v.temperature[1] }
                ), {min:null, max:null}))
            setRules(rulesList);
        }
    }, [temperatureRules, setRules]);

    const setActive = useCallback(d =>
        dispatch(setActiveChartData({ type, data: { data: d.slice.points, rangeType }})), [dispatch, rangeType, type]);

    const isPrediction = dateType === 'month' && isSameCurrentDateByDay(to);
    return useMemo(() => (
        <div className={styles.details_charts}>
            <div className={styles.chart_box}>
                <InformationTopPanel card={card} type={type} />
                <div className={`${ styles.chart} line-chart`}>
                    <Charts.LineChart
                        data={data}
                        rules={rules}
                        rangeType={rangeType}
                        dateType={dateType}
                        setActive={setActive}
                        isPrediction={isPrediction}
                        from={from}
                    />
                </div>
                <CalendarWithTabsWrap
                    dateType={dateType}
                    setRangeType={setRangeType}
                    setDateType={setDateType}
                    setFrom={setFrom}
                    setTo={setTo}
                    from={from}
                    to={to}
                />
            </div>
            <hr/>
            <InformationBottomPanel card={card} type={type} />
        </div>
    ), [card, type, dateType, data, rules, rangeType, setRangeType, setDateType, setFrom, setTo, setActive, isPrediction]);
}

export default DetailsCharts;