import React, {useEffect, useState} from 'react';
import {Form} from "react-bootstrap";
import ProfileInfo from './ProfileInfo';
import BottomInfo from "./BottomInfo";

import { useSelector } from 'react-redux';
import { getProfileInfo } from "../../redux/selectors";
import { Page } from "../index";
import styles from "../../styles/components/Page.module.css";

const config = [
    {
        name: 'email',
        title: 'Profile.email',
        value: '',
        placeholder: 'Profile.email',
        readOnly: true 
    },
    {
        name: 'phone_number',
        title: 'Profile.phoneNumber',
        value: '',
        placeholder: 'Profile.phoneNumber',
        readOnly: false,
        className: 'mb-0'
    },
    {
        name: 'password',
        title: 'Profile.password',
        value: '',
        placeholder: 'Profile.password',
        readOnly: true,
        className: 'mb-0'
    },
    {
        name: 'title',
        title: 'Profile.title',
        value: '',
        placeholder: 'Profile.title',
        readOnly: false
    },
    {
        name: 'first_name',
        title: 'Profile.firstName',
        value: '',
        placeholder: 'Profile.firstName',
        readOnly: false,
        multiple: true
    },
    {
        name: 'last_name',
        title: 'Profile.lastName',
        value: '',
        placeholder: 'Profile.lastName',
        readOnly: false,
        multiple: true
    },
    {
        name: 'birthdate',
        title: 'Profile.date',
        type: 'date',
        value: '',
        placeholder: 'Profile.date',
        readOnly: false
    },
    {
        name: 'address',
        title: 'Profile.address',
        value: '',
        placeholder: 'Profile.address',
        readOnly: false
    },
    {
        name: 'postcode',
        title: 'Profile.postcode',
        value: '',
        placeholder: 'Profile.postcode',
        readOnly: false,
        multiple: true
    },
    {
        name: 'city',
        title: 'Profile.city',
        value: '',
        placeholder: 'Profile.city',
        readOnly: false,
        multiple: true
    },
    {
        name: 'department',
        title: 'Profile.department',
        value: '',
        placeholder: 'Profile.department',
        readOnly: false,
        multiple: true,
        className: 'mb-0'
    },
    {
        name: 'position',
        title: 'Profile.position',
        value: '',
        placeholder: 'Profile.position',
        readOnly: false,
        multiple: true,
        className: 'mb-0'
    },
    {
        name: 'company_name',
        title: 'Profile.companyName',
        value: '',
        placeholder: 'Profile.companyName',
        readOnly: false,
    },
    {
        name: 'company_title',
        title: 'Profile.title',
        value: '',
        placeholder: 'Profile.title',
        readOnly: false
    },
    {
        name: 'company_last_name',
        title: 'Profile.lastName',
        value: '',
        placeholder: 'Profile.lastName',
        readOnly: false,
        multiple: true
    },
    {
        name: 'company_first_name',
        title: 'Profile.firstName',
        value: '',
        placeholder: 'Profile.firstName',
        readOnly: false,
        multiple: true
    },
    {
        name: 'company_birthdate',
        title: 'Profile.date',
        type: 'date',
        value: '',
        placeholder: 'Profile.date',
        readOnly: false
    },
    {
        name: 'company_address',
        title: 'Profile.address',
        value: '',
        placeholder: 'Profile.address',
        readOnly: false
    },
    {
        name: 'company_postcode',
        title: 'Profile.postcode',
        value: '',
        placeholder: 'Profile.postcode',
        readOnly: false,
        multiple: true
    },
    {
        name: 'company_city',
        title: 'Profile.city',
        value: '',
        placeholder: 'Profile.city',
        readOnly: false,
        multiple: true
    },
    {
        name: 'company_country',
        title: 'Profile.companyCountry',
        value: '',
        placeholder: 'Profile.companyCountry',
        readOnly: false,
        className: 'mb-0'
    }
]

const Profile = () => {
    const [profileData, setProfileData] = useState([]);
    const [personalData, setPersonalData] = useState([]);
    const [companyData, setCompanyData] = useState([]);
    const profileInfo = useSelector(getProfileInfo);
    
    useEffect(() => {
        config.forEach(field => {
            if (profileInfo[field.name] && field.name !== 'password') {
                field.value = profileInfo[field.name]
            }
        })

        setProfileData(config.slice(0, 3));
        setPersonalData(config.slice(3, 12));
        setCompanyData(config.slice(12));

    }, [profileInfo])

    return (
        <Page header={'profile'}>
            <hr className={`${styles.header_divider} opacity-1`} />
            <Form>
                <ProfileInfo profileInfo={profileData} />
                <BottomInfo personalInfo={personalData} companyInfo={companyData} />
            </Form>
        </Page>
    )
};

export default Profile;