import React, {useEffect, useState} from 'react';
import styles from '../../styles/components/Sencors/SensorCard.module.css'
import stylesPrototype from '../../styles/components/Sencors/SensorCardPrototype.module.css';
import * as animationDataRed from '../../images/performance/prototype/arrow_red_increasing.json';
import {removeActiveSensor} from "../../redux/actions";
import {useDispatch} from "react-redux";
import CloseIcon from '@mui/icons-material/Close';
import Lottie from "lottie-react";
import {useTranslation} from "react-i18next";

const SensorCard = props => {
  const { isModal = false } = props;
  const name = 'sensorPrototype';
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [animationData, setAnimationData] = useState(null);


  const hideModal = () => {
    dispatch(removeActiveSensor(97));
  };

  useEffect(() => {
    document.querySelectorAll('.temp_arrow_decreasing').forEach(e => e.style.opacity = 0);
    //document.querySelector('#arrow_red_increasing').style.opacity = 0;

    let timer_animateValue;
    function animateValue(id, start, end, duration) {
      // assumes integer values for start and end

      const obj = document.getElementById(id);
      const range = end - start;
      // no timer shorter than 50ms (not really visible any way)
      const minTimer = 50;
      // calc step time to show all interediate values
      let stepTime = Math.abs(Math.floor(duration / range));

      // never go below minTimer
      stepTime = Math.max(stepTime, minTimer);

      // get current time and calculate desired end time
      const startTime = new Date().getTime();
      const endTime = startTime + duration;

      function run() {
        const now = new Date().getTime();
        const remaining = Math.max((endTime - now) / duration, 0);
        const value = Math.round(end - (remaining * range));
        obj.innerHTML = value;
        if (+value === +end) {
          clearInterval(timer_animateValue);
        }
      }

      timer_animateValue = setInterval(run, stepTime);
      run();
    }
    let timer_animate_slider;
    function animateValueSlider(id, start, end, duration) {
      // assumes integer values for start and end

      const obj = document.getElementById(id);
      const range = end - start;
      // no timer shorter than 50ms (not really visible any way)
      const minTimer = 50;
      // calc step time to show all interediate values
      let stepTime = Math.abs(Math.floor(duration / range));

      // never go below minTimer
      stepTime = Math.max(stepTime, minTimer);

      // get current time and calculate desired end time
      const startTime = new Date().getTime();
      const endTime = startTime + duration;

      const rangeValue = document.querySelector('.range-value');
      function run() {
        const now = new Date().getTime();
        const remaining = Math.max((endTime - now) / duration, 0);
        const value = Math.round(end - (remaining * range));
        obj.value = value;
        rangeValue.innerHTML = value;

        obj.style.backgroundSize = value * 100 / 100 + '% 100%';
        if (+value === +end) {
          clearInterval(timer_animate_slider);
        }
      }
      timer_animate_slider = setInterval(run, stepTime);
      run();
    }

    // ----------- INPUT SLIDER
    const range = document.querySelector('.input-range'),
        value = document.querySelector('.range-value');

    value.innerHTML = range.attributes['value'].value;

    range.addEventListener('input', function(e) {
      e.target.style.backgroundSize = (this.value - 0) * 100 / 100 + '% 100%';
      value.innerHTML = this.value;
    });

    // ----------- GET VALUES
    const slider = document.getElementsByClassName("input-range")[0];

    // const vorlauf_temp = document.getElementById("vorlauf_value");
    // const ruecklauf_temp = document.getElementById("ruecklauf_value");

    // const volumenstrom = document.getElementById("volumenstrom_value");
    // const delta = document.getElementById("delta_value");



    slider.addEventListener("change", berechnen);

    function berechnen({oeffnungswinkel_value_new = null}) {

      const oeffnungswinkel_value = oeffnungswinkel_value_new || +slider.value;

      // const vorlauf_value = vorlauf_temp.value;
      // const ruecklauf_value = ruecklauf_temp.value;

      let timer_1, timer_2, timer_3, timer_4;

      if(timer_1) clearTimeout(timer_1);
      if(timer_2) clearTimeout(timer_2);
      if(timer_3) clearTimeout(timer_3);
      if(timer_4) clearTimeout(timer_4);

      if (oeffnungswinkel_value === 50) {
        setTimeout(function() {
          let start = +document.querySelector('#volumenstrom_value').innerText || 7000;
          if(timer_animateValue)  clearInterval(timer_animateValue);
          animateValue("volumenstrom_value", start, 4200, 30000);
        }, 3000);
        document.querySelector('#bg_heizkessel_on').classList.add('fadeTo_5000_0');
        document.querySelectorAll('.temp_arrow_decreasing').forEach(e => e.classList.add('fadeTo_9000_1'));
        //document.querySelector('#arrow_red_increasing').classList.add('fadeTo_5000_1');

        timer_1 = setTimeout(() => {
          document.querySelector('#bg_heizkessel_on').style.opacity = 0;
          //document.querySelector('#arrow_red_increasing').style.opacity = 1;
          setAnimationData(animationDataRed);
          document.querySelector('#bg_heizkessel_on').classList.remove('fadeOut_5000');
          //document.querySelector('#arrow_red_increasing').classList.remove('fadeTo_5000_1');
        }, 5000);

        timer_2 = setTimeout(() => {
          document.querySelectorAll('.temp_arrow_decreasing').forEach(e => {
            e.style.opacity = 1;
            e.classList.remove('fadeTo_9000_1');
          })
        }, 9000);
      } else if (oeffnungswinkel_value === 100) {
        setTimeout(function() {
          let start = +document.querySelector('#volumenstrom_value').innerText || 4200;
          if(timer_animateValue) clearInterval(timer_animateValue);
          animateValue("volumenstrom_value", start, 7000, 10000);
        }, 3000);
        // $("#arrow_red_increasing").fadeTo(5000, 0);
        //document.querySelector('#arrow_red_increasing').classList.add('fadeTo_5000_0');
        document.querySelector('#bg_heizkessel_on').classList.add('fadeTo_5000_1');

        document.querySelectorAll('.temp_arrow_decreasing').forEach(e => e.classList.add('fadeTo_9000_0'));

        timer_3 = setTimeout(() => {
          setAnimationData(null);
          //document.querySelector('#arrow_red_increasing').style.opacity = 0;
          //document.querySelector('#arrow_red_increasing').classList.remove('fadeTo_5000_0');
          document.querySelector('#bg_heizkessel_on').style.opacity = 1;
          document.querySelector('#bg_heizkessel_on').classList.remove('fadeTo_5000_1');

        }, 5000);

        timer_4 = setTimeout(() => {
          document.querySelectorAll('.temp_arrow_decreasing').forEach(e => {
            e.style.opacity = 0;
            e.classList.remove('fadeTo_9000_0');
          })
        }, 9000);
      }
      else {
        if (oeffnungswinkel_value < 75) {
          if (timer_animate_slider) clearInterval(timer_animate_slider);
          animateValueSlider("input-range", oeffnungswinkel_value, 50, 500);
          berechnen({ oeffnungswinkel_value_new: 50 })
        } else {
          if (timer_animate_slider) clearInterval(timer_animate_slider);
          animateValueSlider("input-range", oeffnungswinkel_value, 100, 500);
          berechnen({ oeffnungswinkel_value_new: 100 })
        }
      }

      // console.log(ruecklauf_value);
      //   vorlauf_temp.innerHTML = oeffnungswinkel_value - 10;
      //   ruecklauf_temp.innerHTML = ruecklauf_value;
    }
  }, []);

  return (
      <div className={[styles.container + ' sensor-card ' + stylesPrototype.wrap_card]}  id="card">
        <div className={stylesPrototype.card}>
          <div className="draggable-place">
            <p className={`${stylesPrototype.h1} h1`} id="dinh">
              {t(`${name}.header`)}
              {isModal ? (
                  <button type="button" className={`btn ${styles.cancel_btn} ${stylesPrototype.btn_close} cancel_btn`} onClick={hideModal}>
                    <CloseIcon  className={''}/>
                  </button>
              ) : null}
            </p>
            <p className={`${stylesPrototype.h2} h2`}>{t(`${name}.h`)}</p>
            <p className={stylesPrototype.oeffnungswert}>
              <span className={`${stylesPrototype.range_value} range-value`}/>
              <span className={`${stylesPrototype.prozent} ${stylesPrototype.to_bottom}`}>%</span>
            </p>
          </div>

          <div className={stylesPrototype.range_slider}>
            <input id='input-range' className={`input-range ${stylesPrototype.input_range} styled-slider slider-progress`} type="range" step="1" defaultValue="100" min="0" max="100"/>
          </div>

          <div className={stylesPrototype.vorlauf_container}>
            <div className={stylesPrototype.oeffnungswert}>
              <span className={`${stylesPrototype.temperature} ${stylesPrototype.vorlauf_value} vorlauf_value`} id="vorlauf_value">83</span>
              <span className={stylesPrototype.prozent}>°C</span>
            </div>
            <p className={stylesPrototype.vorlauf_text}>{t(`${name}.fastForward`)}</p>
          </div>

          <div className={stylesPrototype.ruecklauf_container}>
            <div className={stylesPrototype.oeffnungswert}>
              <div className={stylesPrototype.arrow_red_increasing}>
                <Lottie animationData={animationData} />
              </div>
              <span className={stylesPrototype.temperature} id="ruecklauf_value">75</span>
              <span className={stylesPrototype.prozent}>°C</span>
            </div>
            <p className={stylesPrototype.vorlauf_text}>{t(`${name}.rewind`)}</p>
          </div>

          <div className={stylesPrototype.divider}>
            <hr className={stylesPrototype.solid} />
          </div>


          <div className={`${stylesPrototype.bottomrow} row align-items-start`}>
            <div className="col">
              <p>
                <img className={`${stylesPrototype.temp_arrow_decreasing} temp_arrow_decreasing`} src={require('../../images/performance/prototype/temp_arrow_decreasing.png')} alt=""/>
                <span id="volumenstrom_value" className={stylesPrototype.volumenstrom_value}>7000</span>
                <span className={stylesPrototype.einheit}>l/h</span>
              </p>
              <p className={stylesPrototype.einheitenbeschreibung}>{t(`${name}.volumeFlow`)}</p>
            </div>
            <div className="col">
              <p>
                <img className={`${stylesPrototype.temp_arrow_decreasing} temp_arrow_decreasing`} src={require('../../images/performance/prototype/temp_arrow_decreasing.png')} alt="" />
                <span id="delta_value" className={stylesPrototype.delta_value}>8</span>
                <span className={stylesPrototype.einheit}>K</span>
              </p>
              <p className={stylesPrototype.einheitenbeschreibung}>Delta</p>
            </div>
            <div className="col">
              <p>
                <img className={`${stylesPrototype.temp_arrow_decreasing} temp_arrow_decreasing`} src={require('../../images/performance/prototype/temp_arrow_decreasing.png')} alt="" />
                65,1
                <span className={stylesPrototype.einheit}>kWh</span>
              </p>
              <p className={stylesPrototype.einheitenbeschreibung}>{t(`${name}.performance`)}</p>
            </div>
          </div>
        </div>
      </div>
  )
};

export default SensorCard;
