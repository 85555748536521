import React, {useState} from 'react';
import {Button} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {useTranslation} from "react-i18next";
import AddCompanyCard from "./AddCompanyCard";
import {useSelector} from "react-redux";
import {getCompanyCreate, getCompanyEdit} from "../../redux/selectors";
import {isEmptyCompany} from "../../utils/functions";
import CompanyInformation from "./CompanyInformation";

const CompanySettings = props => {
    const name = 'CompanySettings';
    const {t} = useTranslation();
    const { isEdit } = props;
    const company = useSelector(isEdit ? getCompanyEdit : getCompanyCreate)
    const [open, setOpen] = useState(false);
    const handleOpenModalAddCompany = () => { if (!open) {setOpen(true)} }
    const handleCloseModalAddCompany = () => setOpen(false);

    return (
        <div>
            <div className={'flex-row flex-between mb-70'}>
                <h2>{t(`${name}.header`)}</h2>
                { isEmptyCompany(company) ? (
                    <Button
                        variant="contained"
                        className={'btn-primary'}
                        disableElevation
                        endIcon={<AddIcon sx={{ width: 25, height: 25 }} />}
                        onClick={handleOpenModalAddCompany}
                    >
                        {t(`${name}.add`)}
                    </Button>
                ) : null}
                <AddCompanyCard handleCloseDetails={handleCloseModalAddCompany} open={open} isEdit={isEdit} />
            </div>
            { !isEmptyCompany(company) ? (
                <CompanyInformation onClick={handleOpenModalAddCompany} company={company} />
            ) : null}
        </div>
    )
}

export default CompanySettings;