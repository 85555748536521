export const ADD_COMPANY = 'ADD_COMPANY';
export const ADD_COMPANY_SUCCEEDED = 'ADD_COMPANY_SUCCEEDED';
export const ADD_COMPANY_FAILED = 'ADD_COMPANY_FAILED';
export const EDIT_COMPANY = 'EDIT_COMPANY';
export const EDIT_COMPANY_SUCCEEDED = 'EDIT_COMPANY_SUCCEEDED';
export const EDIT_COMPANY_FAILED = 'EDIT_COMPANY_FAILED';
export const GET_COMPANY = 'GET_COMPANY';
export const GET_COMPANY_SUCCEEDED = 'GET_COMPANY_SUCCEEDED';
export const GET_COMPANY_FAILED = 'GET_COMPANY_FAILED ';
export const GET_COMPANIES_LIST = 'GET_COMPANIES_LIST';
export const GET_COMPANIES_LIST_SUCCEEDED = 'GET_COMPANIES_LIST_SUCCEEDED';
export const GET_COMPANIES_LIST_FAILED = 'GET_COMPANIES_LIST_FAILED ';
export const SET_TEMPLATE_EDIT_COMPANY = 'SET_TEMPLATE_EDIT_COMPANY';

export const ADD_COMPANY_PROPERTY = 'ADD_COMPANY_PROPERTY';
export const ADD_COMPANY_PROPERTY_SUCCEEDED = 'ADD_COMPANY_PROPERTY_SUCCEEDED';
export const ADD_COMPANY_PROPERTY_FAILED = 'ADD_COMPANY_PROPERTY_FAILED';
export const REMOVE_COMPANY_PROPERTY = 'REMOVE_COMPANY_PROPERTY';
export const REMOVE_COMPANY_PROPERTY_SUCCEEDED = 'REMOVE_COMPANY_PROPERTY_SUCCEEDED';
export const REMOVE_COMPANY_PROPERTY_FAILED = 'REMOVE_COMPANY_PROPERTY_FAILED';

export const ADD_USER = 'ADD_USER';
export const ADD_USER_SUCCEEDED = 'ADD_USER_SUCCEEDED';
export const ADD_USER_FAILED = 'ADD_USER_FAILED';
export const UPDATE_COMPANY_USER = 'UPDATE_COMPANY_USER';
export const UPDATE_COMPANY_USER_SUCCEEDED = 'UPDATE_COMPANY_USER_SUCCEEDED';
export const UPDATE_COMPANY_USER_FAILED = 'UPDATE_COMPANY_USER_FAILED';
export const SET_PASS_FOR_NEW_USER = 'SET_PASS_FOR_NEW_USER';
export const SET_PASS_FOR_NEW_USER_FAILED = 'SET_PASS_FOR_NEW_USER_FAILED';

export const GET_CONFIRM_USER = 'GET_CONFIRM_USER';
export const GET_CONFIRM_USER_FAILED = 'GET_CONFIRM_USER_FAILED';

export const CLEAR_ERRORS = 'CLEAR_ERRORS';