import * as types from '../types/performance';

const initialState = {
  performanceImg: {
    filters: {
      hotWater: {
        show: false,
        modbus: [151, 232, 233]
      },
      chilledWater: {
        show: false,
        modbus: [101, 100, 22]
      },
      circulation: {
        show: false,
        modbus: [152, 160, 161, 162, 163, 164, 165, 166]
      },
      heatingLeadTime: {
        show: false,
        modbus: [212, 232, 200]
      },
      heatingReturnFlow: {
        show: false,
        modbus: [213, 233, 201, 97]
      },
    }
  }
};

export const performance = (state = initialState.performanceImg, action) => {
  switch (action.type) {
    case types.SET_FILTER_PERFORMANCE:
      return {...state, filters: action.payload};
    case types.RESET_FILTER_PERFORMANCE:
      return {...state, filters: initialState.performanceImg.filters };
    default:
      return state;
  }
};
