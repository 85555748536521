import React, {useEffect, useState} from 'react';
import styles from '../../styles/components/Performance/FilterPerformanceImg.module.css'
import {Accordion, ListGroup} from "react-bootstrap";
import { Checkbox } from "../Inputs/index";
import {useDispatch, useSelector} from "react-redux";
import {setFilterPerformance} from "../../redux/actions/index";
import {getFilterPerformance} from "../../redux/selectors/index";
import {isMobile} from "../../utils/functions";
import {useTranslation} from "react-i18next";

const FilterPerformanceImg = () => {
  const name = "FilterPerformanceImg";
  const dispatch = useDispatch();
  const filters = useSelector(getFilterPerformance);
  const { t } = useTranslation();

  const [hotWater, setHotWater] = useState(filters.hotWater);
  const [chilledWater, setChilledWater] = useState(filters.chilledWater);
  const [circulation, setCirculation] = useState(filters.circulation);
  const [heatingLeadTime, setHeatingLeadTime] = useState(filters.heatingLeadTime);
  const [heatingReturnFlow, setHeatingReturnFlow] = useState(filters.heatingReturnFlow);

  useEffect(() => {
    dispatch(setFilterPerformance({
      hotWater, chilledWater, circulation, heatingLeadTime, heatingReturnFlow
    }))
  }, [hotWater, chilledWater, circulation, heatingLeadTime, heatingReturnFlow, dispatch]);

  const filterList = [
    {
      id: 0,
      label: t(`${name}.ChilledWater`),
      checked: chilledWater.show,
      onChange: v => setChilledWater({...chilledWater, show: v}),
    },
    {
      id: 1,
      label: t(`${name}.HotWater`),
      checked: hotWater.show,
      onChange: v => setHotWater({...hotWater, show: v}),
    },
    {
      id: 2,
      label: t(`${name}.Circulation`),
      checked: circulation.show,
      onChange: v => setCirculation({...circulation, show: v}),
    },
    {
      id: 3,
      label: t(`${name}.HeaterForward`),
      checked: heatingLeadTime.show,
      onChange: v => setHeatingLeadTime({...heatingLeadTime, show: v}),
    },
    {
      id: 4,
      label: t(`${name}.HeatingReturnFlow`),
      checked: heatingReturnFlow.show,
      onChange: v => setHeatingReturnFlow({...heatingReturnFlow, show: v}),
    },
  ];

  return (
    <Accordion className={styles.accordion} defaultActiveKey={isMobile || window.innerWidth < 1440 ? null : '0'} >
      <Accordion.Item eventKey="0"  className={styles.filter_container}>
        <Accordion.Header className={styles.accordion_btn}>
          <h3 className="mb-0">{t(`${name}.label`)}</h3>
        </Accordion.Header>
        <Accordion.Body className={styles.accordion_body} >
          <ListGroup>
            {filterList.map(item => (
             <ListGroup.Item key={item.id} className={styles.list_group_item}>
               <Checkbox
                 label={item.label}
                 checked={item.checked}
                 onChange={item.onChange}
                 positionCheckbox="right_checkbox_position"
               />
             </ListGroup.Item>
           ))}
          </ListGroup>
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  )
};
export default FilterPerformanceImg;
