import React from 'react';
import { Routes, Route, useLocation} from "react-router-dom";
import { useSelector } from 'react-redux';
import {
    Layout,
    UnAuthLayout,
    Login,
    PropertyOverview,
    DetailView,
    Profile,
    UserAdministration,
    ConfirmPage
} from './components';
import { getAuth } from "./redux/selectors";
import { TransitionGroup, CSSTransition } from "react-transition-group";

const AuthRoutes = () => {
    const location = useLocation();
    return (
        <TransitionGroup>
            <CSSTransition
                key={location.key}
                classNames="fade"
                timeout={250}
            >
                <Routes location={location}>
                    <Route path="signup/:access_token" element={<ConfirmPage />} />
                    <Route path="property" element={<PropertyOverview />}>
                        {/*<Route path="details/:id" element={<DetailView />} />*/}
                        <Route path="*" element={<DetailView />} />
                    </Route>
                    <Route path="property/details/:id" element={<DetailView />} />
                    <Route path="user_administration" element={<UserAdministration />} />
                    <Route path="profile" element={<Profile />} />
                    <Route path="*" element={<PropertyOverview />} />
                </Routes>
            </CSSTransition>
        </TransitionGroup>
    )
};

const Routing = () => {
  const location = useLocation();
  const token = useSelector(getAuth);
  return token ? (
    <Layout>
      <AuthRoutes />
    </Layout>
  ) : (
    <UnAuthLayout>
        <TransitionGroup>
            <CSSTransition
                key={location.key}
                classNames="fade"
                timeout={250}
            >
              <Routes location={location}>
                  <Route path="signup/:access_token" element={<ConfirmPage />} />
                  <Route path="login/admin" element={<Login isAdmin />} />
                  <Route path="login" element={<Login />} />
                  <Route path="*" element={<Login />} />
              </Routes>
            </CSSTransition>
        </TransitionGroup>
    </UnAuthLayout>
  )
}

export default Routing;