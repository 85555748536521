import React, {useCallback, useEffect, useState} from 'react';
import {SensorLayoutGrid, SensorList} from "../index";
import {useSelector} from "react-redux";
import {getDataProperty, getFilters} from "../../redux/selectors";
import { filterRule } from '../../utils/constants';
import {isEmpty} from "../../utils/functions";
import styles from "../../styles/components/Sencors/Sensors.module.css";
import {Tab} from "bootstrap";
import {Tabs} from "react-bootstrap";

const combineCard = (c, sensors) => {
    if ( c.modbus === 101) {
        const card22 = sensors.find(c => c.modbus === 22);
        return {...c, sensors: [...c.sensors, ...(card22 ? card22.sensors : [])]}
    } return c;
}
const getFilterModbusList = name => filterRule.find(f => f.name === name).modbus;
const getHasSensor = (name, sensor) => getFilterModbusList(name).includes(sensor.modbus);

const SensorTabs = props => {
  const { nameForm, typeDisplay } = props;
  const [cards, setCards] = useState([]);
  const filters = useSelector(getFilters);
  const filter = filters[nameForm];
  const data = useSelector(getDataProperty);
  const { sensors = [] } = data;

  const filteredCards = useCallback(() => {
    const { display_warnings, chilled_water, hot_water, heater, zirculation } = filter || {};
    return sensors.map(s => combineCard(s, sensors)).filter(sensor => {
      if (sensor.modbus === 22) return false;
      if (display_warnings) {
        return !!(!sensor.status && (
            isEmpty(filter)
            || (!chilled_water && !hot_water && !heater && !zirculation)
            || (chilled_water && getHasSensor('chilled_water', sensor))
            || (hot_water && getHasSensor('hot_water', sensor))
            || (heater && getHasSensor('heater', sensor))
            || (zirculation && getHasSensor('zirculation', sensor))
        ));
      } else return !!(isEmpty(filter)
        || (display_warnings && !sensor.status)
        || (chilled_water && getHasSensor('chilled_water', sensor))
        || (hot_water && getHasSensor('hot_water', sensor))
        || (heater && getHasSensor('heater', sensor))
        || (zirculation && getHasSensor('zirculation', sensor)));
    })
  }, [filter, sensors ]);

  useEffect(() => {
      if (!isEmpty(data)) setCards(filteredCards());
  }, [data, filter, filteredCards]);

  return (
      <Tabs
          transition={false}
          id="controlled-tab-example"
          activeKey={typeDisplay}
          className={[styles.tabs+ ' mb-1']}
      >
          <Tab eventKey="layout-grid">
              <SensorLayoutGrid cards={cards} filter={filter} />
          </Tab>
          <Tab eventKey="list">
              <SensorList cards={cards} filter={filter} />
          </Tab>
      </Tabs>
  )
};

export default SensorTabs;
