import React, { useState } from 'react';
import styles from '../../styles/components/MapPanel/MapPanelCards.module.css'
import arrowSVG from '../../icons/Icon.png'
import Filters from "../Filters";
import {Accordion, Card, Col, Container, Row, useAccordionButton} from "react-bootstrap";
import { MapLayoutGrid} from "../index";
import {useSelector} from "react-redux";
import {getDataProperties} from "../../redux/selectors";
import {useTranslation} from "react-i18next";
import { useCreateFiltersByProperty } from "../../hooks";

const CustomToggle = ({ _, eventKey }) => {
  const decoratedOnClick = useAccordionButton(eventKey, () => setIsOpen(!isOpen));
  const [isOpen, setIsOpen] = useState(true);
  return <button type="button" className={[(isOpen ? styles.isOpen : '') + " w-100 h-100"]} onClick={decoratedOnClick}>
    <img src={arrowSVG} alt="v"/>
  </button>;
};

const MapPanelCards = props => {
  const name = "MapPanelCards";
  const { nameForm } = props;
  const properties = useSelector(getDataProperties);
  const { t } = useTranslation();
  const filters = useCreateFiltersByProperty({ types: ['city', 'zip', 'warning'], nameForm}) || {};

  const reduceFunc = (obj, name) => obj.reduce((acc, c) => ([...acc, { value: c[name], label: c[name], count: c.count}]), []);

  const filterList = [
    {
      text: t(`Filters.warnings`),
      value: 'display_warnings',
      id: 0,
      showIf: filters.warning && properties.length >= 7
    },
    {
      defaultValue: t(`Filters.city`),
      value: 'city',
      options: filters.city ? reduceFunc(filters.city, 'city') : [],
      id: 1,
      type: 'selector',
      show: false
    },
    {
      defaultValue: t(`Filters.zip`),
      value: 'zip',
      options: filters.zip ? reduceFunc(filters.zip, 'zip') : [],
      id: 2,
      type: 'selector',
      show: false
    },
  ];
  return (
    <Accordion defaultActiveKey="0" className={styles.container}>
      <Card className={styles.card}>
        <Card.Header className={styles.card_header}>
          <CustomToggle eventKey="0" />
        </Card.Header>
        <Accordion.Collapse eventKey="0">
          <Card.Body className={styles.card_body}>
            <Container fluid="md" className={styles.container_md}>
              <Row className="mb-20">
                <Col>
                  <Filters filterList={filterList} nameForm={nameForm} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <MapLayoutGrid nameForm={nameForm} />
                </Col>
              </Row>
            </Container>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  )
};

export default MapPanelCards;
