import React, {useEffect, useState} from 'react';
import {Page, CompaniesList, CompaniesSteps} from "../index";
import styles from '../../styles/components/UserAdministration/UserAdministration.module.css'
import {Box} from "@mui/material";
import {Tabs} from "react-bootstrap";
import {Tab} from "bootstrap";
import {useTranslation} from "react-i18next";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import {getProfileInfo, getRole} from "../../redux/selectors";
import {useDispatch, useSelector} from "react-redux";
import {getCompany} from "../../redux/actions";

const UserAdministration = () => {
    const name = 'UserAdministration';
    const role = useSelector(getRole);
    const user = useSelector(getProfileInfo);
    const [tabType, setTabType] = useState('create');
    const [openEdit, setOpenEdit] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    useEffect(() => {
        if (role !== 'SUPER_ADMIN' && user.company) dispatch(getCompany(user.company))
    }, [role, user])

    return (
        <Page header={'UserAdministration'}>
            {role === 'SUPER_ADMIN' ? (
                <Tabs
                    transition={false}
                    activeKey={tabType}
                    onSelect={setTabType}
                    className={`tabs ${styles.tabs} userAdministration_tabs`}
                >
                    <Tab eventKey="create" title={t(`${name}.tabs.newAllocations`)} className="tab">
                        <CompaniesSteps />
                    </Tab>
                    <Tab eventKey="companyList" title={t(`${name}.tabs.existingAssignments`)} className="tab position-relative">

                        <TransitionGroup>
                            <CSSTransition
                                key={openEdit}
                                classNames="fade-tabs"
                                timeout={500}
                            >
                                {openEdit ? (
                                    <CompaniesSteps setOpenEdit={setOpenEdit} isEdit />
                                ) : (
                                    <Box className={`${styles.box} pt-70`}>
                                        <hr className={styles.hr}/>
                                        <CompaniesList setOpenEdit={setOpenEdit} />
                                    </Box>
                                )}
                            </CSSTransition>
                        </TransitionGroup>
                    </Tab>
                </Tabs>
            ) : (
                <CompaniesSteps setOpenEdit={setOpenEdit} isEdit />
            )}
        </Page>
    )
}

export default UserAdministration