import React, {useEffect, useState} from 'react';
import styles from '../../styles/components/Houses/HousesList.module.css'
import { useParams } from 'react-router';
import {housesRule} from "../../utils/constants";
import SensorCardForHouseList from "./SensorCardForHouseList";
import {useTranslation} from "react-i18next";

const HousesList = () => {
  const name = 'HousesList';
  const { t } = useTranslation();
  const { id: _id } = useParams();
  const id = _id.split('&')[0];
  const [houseCards, setHouseCards] = useState([]);
  const houseList = houseCards ? Object.keys(houseCards) : [];

  useEffect(() => {
    setHouseCards(housesRule[id])
  }, [id]);

  return houseList.map(number => (
      <div key={number} className={styles.house_wrap}>
        <h2 className="mb-50">{`${t(`${name}.components`)}${ number !== "null" ? t(`${name}.house`, {number}) : ''}`}</h2>
        <div className={styles.house_list}>
          {houseCards[number].map(card => <SensorCardForHouseList card={card} key={card.id} />)}
        </div>
      </div>
  ))
};

export default HousesList;
