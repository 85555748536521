import * as types from '../types/userAdministration';

const defCompaniesList = [
    {
        _id: 2,
        country: 'country',
        city: 'Hamburg',
        zip: '20354',
        street: 'Musterstraße',
        building: '1',
        name: 'GWG Hotel ---- LOCAL',
        properties: [],
        users: [
            { id: 1, email: 'snow.smith@gwg.de', firstName: 'Jon', position: 'Asset Manager', role: 'COMPANY_ADMIN', accessSince: '01.04.2022', status: 2 },
            { id: 2, email: 'lannister.smith@gwg.de', firstName: 'Cersei', position: 'Asset Manager', role: 'USER', accessSince: '15.02.2022', status: 1 },
            { id: 3, email: 'lannister.smith@gwg.de', firstName: 'Jaime', position: 'Asset Manager', role: 'USER', accessSince: '14.03.2022', status: 2 },
            { id: 4, email: 'stark.smith@gwg.de', firstName: 'Arya', position: 'Technischer Property Manager', role: 'USER', accessSince: '11.01.2022', status: 2 },
            { id: 5, email: 'targaryen.smith@gwg.de', firstName: 'Daenerys', position: 'Technischer Property Manager', role: 'USER', accessSince: '21.12.2021', status: 2 },
            { id: 6, email: 'melisandre.smith@gwg.de', firstName: '', position: 'Technischer Property Manager', role: 'USER', accessSince: '23.11.2021', status: 2 },
            { id: 7, email: 'clifford.smith@gwg.de', firstName: 'Ferrara', position: 'Technischer Property Manager', role: 'USER', accessSince: '22.11.2021', status: 1 },
            { id: 8, email: 'frances.smith@gwg.de', firstName: 'Rossini', position: 'Asset Manager', role: 'USER', accessSince: '22.11.2021', status: 2 },
            { id: 9, email: 'roxie.smith@gwg.de', firstName: 'Harvey', position: 'Asset Manager', role: 'USER', accessSince: '21.11.2021', status: 2 },
        ]
    },
]

const initialState = {
  userAdministration: {
    error: [],
    companiesList: [],
    template: {
        create: {
            company: {
                properties: [],
                users: []
            }
        },
        edit: {
            company: {
                properties: [],
                users: []
            }
        },
     }
  },
};

export const userAdministration = (state = initialState.userAdministration, { type, payload }) => {
  const tempType = payload?.isEdit ? 'edit' : 'create';
  switch (type) {
      case types.ADD_COMPANY_SUCCEEDED:
          return {...state,
              companiesList: [
                  ...state.companiesList,
                  payload,
              ],
              template: {
                  ...state.template,
                  create: {
                      ...state.template.create,
                      company: {
                          ...state.template.create.company,
                          ...payload
                      }
                  }
              }
          };
    case types.ADD_COMPANY_PROPERTY_SUCCEEDED:
      return {...state,
          companiesList: state.companiesList
              .filter(c => c._id === payload.data._id ? payload.data : c),
          template: {
            ...state.template,
              [tempType]: {
                  ...state.template[tempType],
                  company: payload.data
              }
            }
      };
    case types.REMOVE_COMPANY_PROPERTY_SUCCEEDED:
      return {...state,
          companiesList: state.companiesList
              .filter(c => c._id === payload.data._id ? payload.data : c),
          template: {
            ...state.template,
              [tempType]: {
                  ...state.template[tempType],
                  company: payload.data
              }
            }
      };
    case types.EDIT_COMPANY_SUCCEEDED:
      return {...state,
          companiesList: state.companiesList.map(c => c._id === payload._id ? payload : c),
          template: {
              ...state.template,
              [tempType]: {
                  ...state.template[tempType],
                  company: {
                    ...state.template[tempType].company,
                    ...payload.data
                  }
              }
            }
      };
    case types.GET_COMPANY_SUCCEEDED:
      return {...state,
          companiesList: [
              ...state.companiesList,
              payload,
          ],
          template: {
              ...state.template,
              edit: {
                  ...state.template.edit,
                  company: payload
              }
          }
      };
    case types.GET_COMPANIES_LIST_SUCCEEDED:
      return {...state, companiesList: [
              // ...defCompaniesList,
              ...payload,
          ]};
    case types.SET_TEMPLATE_EDIT_COMPANY:
      const { properties = [], users = [], ...info} = payload;
      return {...state, template: {
          ...state.template,
          edit: {
            ...state.template.edit,
              company: { ...info, properties, users }
          }
        }};
      case types.ADD_USER_SUCCEEDED:
          return {...state,
              companiesList: state.companiesList
                  .map(c => c._id === payload.data.company ? { ...c, users: c.users.concat(payload.data)} : c),
              template: {
                  ...state.template,
                  [tempType]: {
                      ...state.template[tempType],
                      company: {
                          ...state.template[tempType].company,
                          users: state.template[tempType].company.users.concat(payload.data)
                      }
                  }
              }};
      case types.UPDATE_COMPANY_USER_SUCCEEDED:
          return {...state,
              companiesList: state.companiesList
                  .map(c => c._id === payload.data.company ? { ...c, users: c.users.concat(payload.data)} : c),
              template: {
                  ...state.template,
                  [tempType]: {
                      ...state.template[tempType],
                      company: {
                          ...state.template[tempType].company,
                          users: state.template[tempType].company.users
                              .map(u => u._id === payload.data._id ? payload.data : u)
                      }
                  }
              }};
      case types.ADD_COMPANY_PROPERTY_FAILED:
          return {...state, error: [...state.error, { method: 'addCompanyProperties', ...payload }] };
      case types.REMOVE_COMPANY_PROPERTY_FAILED:
          return {...state, error: [...state.error, { method: 'removeCompanyProperties', ...payload }] };
      case types.ADD_COMPANY_FAILED:
          return {...state, error: [...state.error, { method: 'addCompany', ...payload }] };
      case types.EDIT_COMPANY_FAILED:
          return {...state, error: [...state.error, { method: 'editCompany', ...payload }] };
      case types.GET_COMPANY_FAILED:
          return {...state, error: [...state.error, { method: 'getCompany', ...payload }] };
      case types.GET_COMPANIES_LIST_FAILED:
          return {...state, error: [...state.error, { method: 'getCompaniesList', ...payload }] };
      case types.ADD_USER_FAILED:
          return {...state, error: [...state.error, { method: 'addUser', ...payload }] };
      case types.UPDATE_COMPANY_USER_FAILED:
          return {...state, error: [...state.error, { method: 'updateCompanyUser', ...payload }] };
      case types.SET_PASS_FOR_NEW_USER_FAILED:
          return {...state, error: [...state.error, { method: 'setPassForNewUser', ...payload }] };
      case types.GET_CONFIRM_USER_FAILED:
          return {...state, error: [...state.error, { method: 'getConfirmUser', ...payload }] };
      case types.CLEAR_ERRORS:
          return {...state, error: state.error.filter(item => item.method !== payload) };
    default:
      return state;
  }
};
