import React from 'react';
import {Backdrop, Box, Fade, Typography, Modal as ModalComp} from "@mui/material";
import styles from '../styles/components/Modal.module.css'
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    p: 4,
};

const Modal = props => {
    const { handleCloseDetails, open, children, header = '', id = null, boxId = null,
        hasCancelButton = true, classNameHeader = 'h3', classNameBox = null} = props;
    return (
        <ModalComp
            id={id}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            open={open}
            onClose={handleCloseDetails}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 500 }}
        >
            <Fade in={open}>
                <Box sx={style} className={`${styles.box} ${classNameBox ? styles[classNameBox] : ''}`} id={boxId}>
                    <div className={styles.box_header}>
                        <Typography id="transition-modal-title" variant="h6" component={classNameHeader}>
                            {header}
                        </Typography>
                        {
                            hasCancelButton
                                ? <button type="button" className={`btn ${styles.cancel_btn} cancel_btn`} onClick={handleCloseDetails}>
                                    <CloseRoundedIcon  className={''}/>
                                  </button>
                                : null
                        }
                    </div>
                    {children}
                </Box>
            </Fade>
        </ModalComp>
    )
}

export default Modal;