import React, {useState} from 'react';
import styles from '../../styles/components/Performance/PerformanceTooltip.module.css';
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import moment from 'moment';
import {useTranslation} from "react-i18next";

const TooltipWrap = ({children, id, date}) => (
    <OverlayTrigger
        key={id}
        placement="top"
        overlay={
            <Tooltip id={`tooltip-${id}`} className={styles.tooltip}>
                {date || '- : -'}
            </Tooltip>
        }
    >
        {children}
    </OverlayTrigger>
);

const PerformanceTooltipPrototype = props => {
    const { name, delta = false, value, status, date, index, area } = props;
    const { trackers_positions, id } = area;
    const [positions] = useState(trackers_positions[index]);
    const { top = 0, left = 0 } = positions;
    const { t } = useTranslation();
    return (
        <TooltipWrap id={id} date={date ? moment(date).format(t('time.format')) : null}>
            <div
                className={[styles.container + ' ' + (status ? styles.status_good : styles.status_bad)]}
                style={{ top: `${top}px`, left: `${left}px`}}
            >
                <div className={styles.tracker_name}>{delta ? '△' : name}</div>
                <div className={styles.tracker_temperature}>{delta ? `${value}K` : (<>{value}<span>°C</span></>)}</div>
            </div>
        </TooltipWrap>
    )
};

export default PerformanceTooltipPrototype;
