import { call, put } from 'redux-saga/effects'
import {API, requestSuccess, requestError} from '../../utils/API';
import {setToLocalStorage} from "../../utils/functions";

function* requestWrap(API_type, url, { type, payload, cb }) {
  const { data, isEdit = false } = payload || {};
  try {
    const resp = yield call(API[API_type], url, data ? data : payload);
    yield put(requestSuccess(type, isEdit ? { data: resp, isEdit } : resp));
    if (cb) cb();
  } catch (error) {
    yield put(requestError(type, { message: error.formError} ));
  }
}

export function* addCompany({ type, payload, cb }) {
  try {
    const resp = yield call(API.post, 'companies', payload);
    yield put(requestSuccess(type, resp));
    if (cb) cb();
  } catch (error) {
    yield put(requestError(type, { message: error.formError} ));
  }
}

export function* editCompany({ type, payload, cb }) {
  const { data, isEdit = false } = payload;
  try {
    const resp = yield call(API.post, 'companies', data);
    yield put(requestSuccess(type, { data: resp, isEdit }));
    if (cb) cb();
  } catch (error) {
    yield put(requestError(type, { message: error.formError} ));
  }
}

export function* getCompany({ type, payload, cb }) {
  try {
    const resp = yield call(API.get, `companies/${payload}`);
    yield put(requestSuccess(type, resp));
    if (cb) cb();
  } catch (error) {
    yield put(requestError(type, { message: error.formError} ));
  }
}

export function* getCompaniesList({ type, _, cb }) {
  try {
    const resp = yield call(API.get, 'companies');
    yield put(requestSuccess(type, resp));
    if (cb) cb();
  } catch (error) {
    yield put(requestError(type, { message: error.formError} ));
  }
}

export function* addCompanyProperty({ type, payload, cb }) {
  const { data, isEdit = false } = payload;
  try {
    const resp = yield call(API.post, 'companies', data);
    yield put(requestSuccess(type, { data: resp, isEdit }));
    if (cb) cb();
  } catch (error) {
    yield put(requestError(type, { message: error.formError} ));
  }
}
export function* removeCompanyProperties({ type, payload, cb }) {
  const { data, isEdit = false } = payload;
  try {
    const resp = yield call(API.post, 'companies', data);
    yield put(requestSuccess(type, { data: resp, isEdit }));
    if (cb) cb();
  } catch (error) {
    yield put(requestError(type, { message: error.formError} ));
  }
}

export function* addUser({ type, payload, cb }) {
  const { data, isEdit = false } = payload;
  try {
    const resp = yield call(API.put, 'users', data);
    yield put(requestSuccess(type, { data: resp, isEdit }));
    if (cb) cb();
  } catch (error) {
    yield put(requestError(type, { message: error.formError} ));
  }
}

export function* updateCompanyUser({ type, payload, cb }) {
  const { user, isEdit = false } = payload;
  try {
    const resp = yield call(API.post, 'users/update', user);
    yield put(requestSuccess(type, { data: resp, isEdit }));
    if (cb) cb();
  } catch (error) {
    yield put(requestError(type, { message: error.formError} ));
  }
}

export function* setPassForNewUser({ type, payload, cb }) {
  const { data, isAdmin } = payload;
  const dbType = isAdmin ? 'admins' : 'users';
  try {
    const resp = yield call(API.post, `${dbType}/set_password`, data);
    const isPrototypeMode = data.username === 'Customer';
    yield put(requestSuccess('SIGN_IN', { token: resp.token, isPrototypeMode }));
    setToLocalStorage('token', resp.token);
    if (isPrototypeMode) setToLocalStorage('prototypeMode', true);
    if (cb) cb();
  } catch (error) {
    yield put(requestError(type, { message: error.formError} ));
  }
}

export function* getConfirmUser({ type, payload, cb }) {
  try {
    const resp = yield call(API.post, 'users/get_by_token', payload);
    yield put(requestSuccess('GET_PROFILE_INFO', { ...resp }));
    if (cb) cb();
  } catch (error) {
    yield put(requestError(type, { message: error.formError } ));
  }
}
