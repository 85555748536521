import React, {useCallback, useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import {getDataProperties, getFilters} from "../redux/selectors";

export const useCreateFiltersByParam = props => {
    const {
        types = [], /* city, zip, warning*/
        nameForm = '',
        getParam = getDataProperties
    } = props;
    const param = useSelector(getParam);
    const [hasWarning, setHasWarning] = useState(false);
    const [cityList, setCityList] = useState();
    const [zipList, setZipList] = useState();
    const filters = useSelector(getFilters);
    const filter = filters[nameForm] || {};

    const filterProp = useCallback((byParam = []) => {
        return byParam.find(r => filter[r])
            ? param.filter(p => byParam.find(r => p[r] === filter[r]))
            : param;
    }, [filter, param]);

    const generateList = useCallback((name, byParam = []) => {
        return filterProp(byParam).reduce((acc, p) => {
            if (acc.find(a => a[name] === p[name])) {
                const replaceIndex = acc.findIndex(a => a[name] === p[name])
                return [...acc.map((a, i) => replaceIndex !== i ? a : {...a, count: a.count + 1})]
            } else return [...acc, {[name]: p[name], count: 1}]
        },[]);
    }, [filterProp]);

    const setWarning = useCallback(() => {
        setHasWarning(param.find(p => p.sensors.find(s => !s.status)) !== null);
    }, [param])


    const setCity = useCallback(() => {
        setCityList(generateList('city', ['zip']));
    }, [setCityList, generateList]);

    const setZip = useCallback(() => {
        setZipList(generateList('zip', ['city']));
    }, [setZipList, generateList]);

    const filtersControl = {
        warning: setWarning,
        city: setCity,
        zip: setZip
    }

    const filterResults = {
        warning: hasWarning,
        city: cityList,
        zip: zipList
    }

    useEffect(() => {
        if (param && types) {
            types.forEach(type => filtersControl[type]())
        }
    }, [param, filter]);

    return types.reduce((acc, type) => ({ ...acc, [type]: filterResults[type] }), {});
}