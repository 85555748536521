import {useEffect, useRef, useState} from 'react';
import {getChartData} from "../redux/actions";
import {useDispatch, useSelector} from "react-redux";
import {getDataProperty} from "../redux/selectors";
import {getDateFormat, getTo14Day, isSameCurrentDateByDay, wrapInArray} from "../utils/functions";

export const useGetDataForChart = props => {
    const { rangeType, from, to, modbus, type, chartId, dateType } = props;
    const properties_data = useSelector(getDataProperty)
    const { sensors } = properties_data || {};
    const dispatch = useDispatch();
    const requestUrl = useRef(null);
    const [isPrediction, setIsPrediction] = useState(false);

    useEffect(() => {
        setIsPrediction(dateType === 'month' && isSameCurrentDateByDay(to));
    }, [dateType, to])

    useEffect(() => {
        if (from && to) {
            const modbusesId = typeof modbus === 'number' ? [modbus] : Object.values(modbus).reduce((acc, m) => acc.concat(m), []);
            if (requestUrl.current) clearTimeout(requestUrl.current);
            requestUrl.current = setTimeout(() => {
                const tSensors = sensors ? sensors.filter(s => typeof modbus === 'number' ? modbus === s.modbus : modbusesId.includes(s.modbus)) : [];
                const device_uid_list = tSensors.reduce((acc, item) =>{
                    const d = item.sensors.find(s => s.type === type);
                    if (!d) return acc;
                    else if (acc.find(a => a.uid === d.device_uid)) {
                        const index = acc.findIndex(a => a.uid === d.device_uid)
                        acc[index] = {
                            uid: d.device_uid,
                            modbuses: [...(wrapInArray(acc[index].modbuses) || [])].concat(typeof d.modbus === 'number' ? [d.modbus] : d.modbus),
                        }
                        return acc;
                    }
                    return [...acc, { uid: d.device_uid, modbuses: typeof d.modbus === 'number' ? [d.modbus] : d.modbus }]
                }, [])
                if (!Object.keys(device_uid_list).length) return;
                    const params = {
                        devices: device_uid_list,
                        from: from ? +from.format('X') : null,
                        to: isPrediction ? +getTo14Day(to).format('X') : +to.format('X') ,
                        type: rangeType,
                        prediction: isPrediction
                    }
                    dispatch(getChartData({ params, type, chartId }))
            }, 10);
        }
    }, [getDateFormat(from), getDateFormat(to), rangeType, modbus, dispatch, sensors, type, chartId, isPrediction])
};

