export const GET_PROPERTY = 'GET_PROPERTY';
export const GET_PROPERTIES = 'GET_PROPERTIES';

export const SET_PROPERTIES = 'SET_PROPERTIES';
export const SET_PROPERTY = 'SET_PROPERTY';

export const SET_ACTIVE_PROPERTY = 'SET_ACTIVE_PROPERTY';
export const SET_ACTIVE_SENSOR = 'SET_ACTIVE_SENSOR';
export const ADD_ACTIVE_SENSOR = 'ADD_ACTIVE_SENSOR';
export const REMOVE_ACTIVE_SENSOR = 'REMOVE_ACTIVE_SENSOR';

export const SET_SENSOR = 'SET_SENSOR';

export const SET_FILTERS = 'SET_FILTERS';

export const GET_CHART_DATA = 'GET_CHART_DATA';
export const GET_CHART_DATA_SUCCEEDED = 'GET_CHART_DATA_SUCCEEDED';
export const GET_CHART_DATA_FAILED = 'GET_CHART_DATA_FAILED';
export const SET_ACTIVE_CHART_DATA = 'SET_ACTIVE_CHART_DATA';
export const GET_ACTIVE_CHART_DATA = 'GET_ACTIVE_CHART_DATA';

