import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Modal} from "../index";
import {Button, Form, Row} from "react-bootstrap";
import styles from "../../styles/components/UserAdministration/AddCard.module.css";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {addUser, clearErrors} from "../../redux/actions";
import {Grid, MenuItem, Select} from "@mui/material";
import {Roles} from "../../utils/constants";
import {getKeys} from "../../utils/functions";
import {getRole, getCompanyCreate, getCompanyEdit, getErrorUserAdministration} from "../../redux/selectors";
import {useTranslation} from "react-i18next";

const AddUserCard = props => {
    const { handleCloseDetails, open, isEdit = false } = props;
    const name = 'UserSettings.addUserCard';
    const {t} = useTranslation();
    const error = useSelector(getErrorUserAdministration);
    const [requestError, setRequestError] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const company = useSelector(isEdit ? getCompanyEdit : getCompanyCreate)
    const role = useSelector(getRole);
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        defaultValues: {
            role: "USER",
        }
    });

    const getRoleList = useCallback(() => {
        if (role) {
            if (role === 'SUPER_ADMIN' || role === 'COMPANY_ADMIN') {
                return getKeys(Roles).filter((r) => {
                    if (r !== 'SUPER_ADMIN') return r;
                })
            } else if (role === 'USER') {
                return getKeys(Roles).filter((r) => {
                    if (r !== 'SUPER_ADMIN' && r !== 'COMPANY_ADMIN') return r;
                })
            }
        } return []
    }, [role]);

    const handleAddUser = useCallback(data => {
        setLoading(true);
        dispatch(addUser({
            data: {...data, company: company._id},
            isEdit
        }, () => {
            closeModalWindow();
            setLoading(false);
        }))
    }, [dispatch, setLoading, company]);

    const closeModalWindow = () => {
        reset();
        dispatch(clearErrors('addUser'));
        handleCloseDetails();
    }

    useEffect(() => {
        if (isLoading && requestError) setLoading(false);
    }, [isLoading, requestError]);

    useEffect(() => {
        setRequestError((error && error.find(er => er.method === 'addUser')) || null);
    }, [error]);

    const addUserForm = () => {
        return (
            <div className={'modal-container'}>
                <Form className={styles.add_form} onSubmit={handleSubmit(handleAddUser)}>
                    <Grid container spacing={1}>
                        <Grid item xs={5} className="mb-40 pt-0">
                            <Form.Group controlId="formBasicText" >
                                <Form.Label className={styles.label}>{t(`${name}.first_name`)}</Form.Label>
                                <Form.Control className={'input-modal'} type="text" {...register("first_name", { required: true, min: 1 })} />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={7} className={'mb-40 pt-0'}>
                            <Form.Group controlId="formBasicText">
                                <Form.Label className={styles.label}>{t(`${name}.last_name`)}</Form.Label>
                                <Form.Control className={'input-modal'} type="text" {...register("last_name", { required: true, min: 1 })} />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={12} className={'mb-40 pt-0'}>
                            <Form.Group controlId="formBasicText">
                                <Form.Label className={styles.label}>{t(`${name}.email`)}</Form.Label>
                                <Form.Control className={'input-modal'} type="text" {...register("email", { required: true, min: 1 })} />
                            </Form.Group>
                        </Grid>
                        {
                            role !== 'USER'
                                ? <Grid item xs={4} className={'mb-40 pt-0'}>
                                    <Form.Label className={styles.label}>{t(`${name}.role`)}</Form.Label>
                                    <Select
                                        id="formBasicText"
                                        defaultValue={'USER'}
                                        className={`selector ${styles.modal_card}`}
                                        {...register("role", { required: true })}
                                    >
                                        {
                                            getRoleList().map(role => <MenuItem key={role} value={role}>{Roles[role]}</MenuItem>)
                                        }
                                    </Select>
                                </Grid>
                                : null
                        }
                        <Grid item xs={12} className={'pt-0'}>
                        {
                            errors.first_name || errors.last_name || errors.email
                                ? <small className={styles.error_text}>Empty field</small>
                                : requestError
                                ? <small className={styles.error_text}>{requestError.message}</small>
                                : null
                        }
                        </Grid>
                    </Grid>
                    <Row className={'justify-content-end'}>
                        <Button
                            variant="secondary"
                            type="button"
                            className={'btn-modal-secondary'}
                            onClick={() => closeModalWindow()}
                        >
                            {t(`${name}.cancel`)}
                        </Button>
                        <Button
                            variant="primary"
                            type="submit"
                            className={'btn-modal-primary ms-2'}
                            disabled={isLoading}
                        >
                            {isLoading ? 'LADEN..' : t(`${name}.add`)}
                        </Button>
                    </Row>
                </Form>
            </div>
        )
    }

    return useMemo(() => (
        <Modal boxId={'add_user_card_modal'}
               handleCloseDetails={closeModalWindow}
               open={open}
               header={t(`${name}.header_add`)}
               classNameHeader={'h5'}
               hasCancelButton={false}
               classNameBox={'secondary_box'}
        >
        { addUserForm() }
        </Modal>
    ), [open, handleCloseDetails, isEdit, addUserForm]);
}

export default AddUserCard;