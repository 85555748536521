import React, {useCallback, useEffect} from 'react';
import {useTranslation} from "react-i18next";
import Filters from "../Filters";
import {useCreateFiltersByCompany, useFilterList} from "../../hooks";
import {Box, ButtonBase} from "@mui/material";
import {getRole, selectCompaniesList} from "../../redux/selectors";
import {useDispatch, useSelector} from "react-redux";
import {CompanyInformation} from "../index";
import styles from '../../styles/components/UserAdministration/CompaniesList.module.css'
import {getCompaniesList, setTemplateEditCompany} from "../../redux/actions";

const CompaniesList = props => {
    const { setOpenEdit } = props;
    const name = 'CompaniesList';
    const {t} = useTranslation();
    const role = useSelector(getRole);
    const dispatch = useDispatch();
    const nameForm = 'CompaniesList';
    const companiesList = useSelector(selectCompaniesList);
    const filters = useCreateFiltersByCompany({ types: ['city', 'zip'], nameForm}) || {};
    const reduceFunc = (obj, name) => obj.reduce((acc, c) => ([...acc, { value: c[name], label: c[name], count: c.count}]), []);

    useEffect(() => {
        if (role === 'SUPER_ADMIN') dispatch(getCompaniesList())
    }, [role])

    const filteredCards = useFilterList({ nameForm, data: companiesList });

    const filterList = [
        {
            defaultValue: t(`Filters.city`),
            value: 'city',
            options: filters.city ? reduceFunc(filters.city, 'city') : [],
            id: 1,
            type: 'selector',
            show: false
        },
        {
            defaultValue: t(`Filters.zip`),
            value: 'zip',
            options: filters.zip ? reduceFunc(filters.zip, 'zip') : [],
            id: 2,
            type: 'selector',
            show: false
        }
    ];

    const openEdit = useCallback((company) => {
        dispatch(setTemplateEditCompany(company))
        if (setOpenEdit) setOpenEdit(true);
    }, [dispatch, setOpenEdit])

    return (
        <Box>
            <Box className={'flex-row flex-between mb-70'}>
                <h2>{t(`${name}.header`)}</h2>
            </Box>
            <Filters className={'mb-70'} filterList={filterList} nameForm={nameForm} showFilterIcon={false} />
            <Box className={'mb-140 min-h-200 flex-column'}>
                {filteredCards.map(company => (
                    <ButtonBase
                        key={company._id}
                        variant="link"
                        className={`${styles.card_btn} mb-20 text-initial w-100`}
                        onClick={() => openEdit(company)}
                    >
                        <CompanyInformation key={company._id} company={company} />
                    </ButtonBase>
                ))}
            </Box>
        </Box>
    )
}

export default CompaniesList;