import {call, put, take} from 'redux-saga/effects'
import {socketEmitter} from '../../utils/socket-config';
import {setProperties, setProperty, setSensor} from "../actions";
import {getTypeSensor, getStatus, getStatusSensor, isEmpty} from "../../utils/functions";
import {API, requestSuccess, requestError, sessionExpires} from "../../utils/API";

const normalizeDataProperties = (data = []) => {
  const sensorsToArray = prop => prop.sensors.reduce((acc, point) => {
    const { _id } = prop;
    const findPointIndex = acc.findIndex(s => s.modbus === point.modbus);
    const findPoint = acc.find(s => s.modbus === point.modbus);
    return findPoint
        ? acc.map((item, i) => i === findPointIndex
            ? {
              sensors: [...findPoint.sensors, { ...point, type: getTypeSensor(point) } ],
              modbus: point.modbus,
              status: getStatus([point, ...findPoint.sensors], _id)
            }
            : item)
        : [...acc, {
          sensors: [{ ...point, type: getTypeSensor(point) }],
          modbus: point.modbus,
          status: getStatus([point], _id)
        }];
  }, []);
  const sensorsAddStatus = prop => prop.sensors.map(point => {
    const { _id } = prop;
    const newPoints = point.sensors.map(s => ({...s, status: getStatusSensor(s, _id) }));
    return { ...point, sensors: newPoints }
  });
  const normalizeData = data.map(prop => ({...prop, sensors: sensorsToArray(prop)}));
  return normalizeData.map(prop => ({...prop, sensors: sensorsAddStatus(prop)}));
};

function* tryRequestProperties(type, socketRequest) {
  try {
    while (true) {
      let resp = yield take(socketRequest)
      console.log('update', resp);
      yield responseProperties(resp)
    }
  } catch (error) {
    sessionExpires(error)
    yield put(requestError(type, { message: error.formError} ));
  }
}

function* responseProperties(resp) {
  yield sessionExpires(resp)
  if (resp.type === "properties") {
    const updateData = normalizeDataProperties(resp.data);
    yield put(setProperties(updateData))
  } else if (resp.type === "property") {
    const updateData = normalizeDataProperties(resp.data);
    yield put(setProperty(updateData))
  } else if (resp.type === "sensor") {
    yield put(setSensor(resp.data))
  }
}




export function* getProperties({ type, payload }) {
  const { token } = payload;
  const socketRequest = yield call(socketEmitter, { emit: 'properties', on: 'update', payload: { token } })
  yield tryRequestProperties(type, socketRequest);
}

export function* getProperty({ type, payload }) {
  const { token, id } = payload;
  const socketRequest = yield call(socketEmitter, { emit: 'property', on: 'update', payload: { token, _id: id  } })
  yield tryRequestProperties(type, socketRequest);
}

export function* _getChartData({ type, payload }){
  try {
    const resp = yield call(API.post, 'charts', payload.params);
    yield put(requestSuccess(type, { type: payload.type, data: isEmpty(resp) ? [] : resp, chartId: payload.chartId }));
  } catch (error) {
    console.error('getChartData error');
    // yield put(requestError(type, { message: error.formError} ));
  }
}