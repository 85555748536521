import React, {useCallback, useEffect, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useForm } from "react-hook-form";
import styles from '../styles/components/ConfirmPage.module.css'
import {Button, Form} from "react-bootstrap";
import {getConfirmUser, setPassForNewUser, SignIn} from "../redux/actions";
import {useNavigate, useParams} from "react-router";
import {getErrorUserAdministration, getProfileInfo} from "../redux/selectors";
import {useDocumentTitle} from "../hooks";
import {useTranslation} from "react-i18next";

const ConfirmPage = () => {
  const name = 'ConfirmPage';
  const { access_token } = useParams();
  const password = useRef({});
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { email = '' } = useSelector(getProfileInfo);
  const error = useSelector(getErrorUserAdministration);
  const [requestError, setRequestError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
  password.current = watch('password', '');
  const onSubmit = useCallback(data => {
    setRequestError(false);
    setLoading(true);
    dispatch(setPassForNewUser({ data: { password: data.password, access_token }}, () => {
      setLoading(false);
      navigate('/');
    }))
  }, [dispatch, navigate, setLoading, setRequestError]);
  useDocumentTitle('Paul - Let’s get started');

  useEffect(() => {
    dispatch(getConfirmUser({ access_token }))
  }, [dispatch]);

  useEffect(() => {
    if (isLoading && requestError) setLoading(false);
  }, [isLoading, requestError]);

  useEffect(() => {
    setRequestError((error && error.find(er => er.method === 'setPassForNewUser')) || null);
  }, [error]);

  return (
    <div className={`container flex-center ${styles.confirm_form_wrap}`}>
      <Form className={styles.confirm_form} onSubmit={handleSubmit(onSubmit)}>
        <h2 className={'mb-2'}>{t(`${name}.header`)}</h2>
        <div className={'flex-column flex-start mb-24'}>
          <span>{t(`${name}.text`)}</span>
          <span className={'fw-bold'}>{email}</span>
        </div>
        <Form.Group className="mb-4" controlId="formBasicPassword">
          <Form.Label className={styles.label}>Password</Form.Label>
          <Form.Control type="password"
                        {...register("password", {
                          required: "You must specify a padssword",
                          minLength: {
                            value: 8,
                            message: "Password must have at least 8 characters"
                          }
                        })}
          />
        </Form.Group>
        <Form.Group className="mb-4" controlId="formBasicPassword">
          <Form.Label className={styles.label}>Repeat Password</Form.Label>
          <Form.Control type="password"
                        {...register("confirmPwd", {
                          validate: v => v === password.current || "The passwords do not match"
                        })}
          />
        </Form.Group>
        <Button
          variant="primary"
          type="submit"
          className="w-100"
          disabled={isLoading}
        >
          {isLoading ? 'LADEN..' : 'SIGN IN'}
        </Button>
        {errors.password && <small className={styles.error_text}>{errors.password.message}</small>}
        {errors.confirmPwd && !errors.password && <small className={styles.error_text}>{errors.confirmPwd.message}</small>}
        {requestError && <small className={styles.error_text}>Ungültiger Token</small>}
      </Form>
    </div>
  )
};

export default ConfirmPage;
