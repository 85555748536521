import React, {useState} from 'react';
import {PipesChartWrap} from "../index";
import {useTranslation} from "react-i18next";
import {getFrom16Day} from "../../utils/functions";
import moment from "moment";

const PipesDetails = props => {
    const name = 'PipesDetails';
    const { card } = props;
    const { header = '' } = card || {};

    const [hoverTypeLine, setHoverTypeLine] = useState();
    const [checkedLegend, setCheckedLegend] = useState(
        ['pipes', 'returnFlow', 'storageTank', 'minTemperature', 'anomalies']
    )
    const [dateType, setDateType] = useState('month');
    const [from, setFrom] = useState(getFrom16Day());
    const [to, setTo] = useState(moment());
    const [rangeType, setRangeType] = useState(1);

    const { t } = useTranslation();

    return (
        <div>
            <h2 className="mb-50">{t(`${name}.title`)}</h2>
            <div className={'container-box-shadow bg-color-main-color-form-bg px-25 pt-15 pb-40'}>
                <p className={'container-header'} style={{margin: '0 0 6px 0'}}>{t(`${name}.${header}`)}</p>
                <PipesChartWrap
                    card={card}
                    type={'PAUL'}
                    hoverTypeLine={hoverTypeLine}
                    checkedLegend={checkedLegend}
                    setHoverTypeLine={setHoverTypeLine}
                    setCheckedLegend={setCheckedLegend}
                    dateType={dateType}
                    from={from}
                    to={to}
                    rangeType={rangeType}
                    setDateType={setDateType}
                    setFrom={setFrom}
                    setTo={setTo}
                    setRangeType={setRangeType}
                />
            </div>
        </div>
    )
}

export default PipesDetails;