import React, {useEffect} from 'react';
import {Box, Button} from "@mui/material";
import {CompanySettings, PropertySettings, UserSettings} from "../index";
import styles from "../../styles/components/UserAdministration/UserAdministration.module.css";
import {useSelector} from "react-redux";
import {getRole} from "../../redux/selectors";

const CompaniesSteps = props => {
    const name = 'CompaniesSteps';
    const role = useSelector(getRole);
    const { isEdit = false, setOpenEdit } = props;

    useEffect(() => {
        if (isEdit) {
            document.querySelector('.userAdministration_tabs')?.scrollIntoView();
        }
    }, [isEdit])

    return (
        <Box className={styles.box}>
            {isEdit && role === 'SUPER_ADMIN' &&
                <Button variant="contained" className={`btn-primary ${styles.back_btn}`} onClick={() => setOpenEdit(false)}>Back to list</Button>
            }
            <hr className={styles.hr}/>
            <CompanySettings isEdit={isEdit} />
            <hr className={styles.hr}/>
            <PropertySettings isEdit={isEdit} />
            <hr className={styles.hr}/>
            <UserSettings isEdit={isEdit} />
        </Box>
    )
}

export default CompaniesSteps;