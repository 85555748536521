import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Modal} from "../index";
import {Button, Form, Row} from "react-bootstrap";
import styles from "../../styles/components/UserAdministration/AddCard.module.css";
import {useForm} from "react-hook-form";
import {addCompany, clearErrors, editCompany} from "../../redux/actions";
import {useDispatch, useSelector} from "react-redux";
import {getCompanyCreate, getCompanyEdit, getErrorUserAdministration} from "../../redux/selectors";
import {isEmptyCompany} from "../../utils/functions";
import {Grid} from "@mui/material";
import {useTranslation} from "react-i18next";

const AddCompanyCard = props => {
    const { handleCloseDetails, open, isEdit = false } = props;
    const name = 'UserSettings.addCompanyCard';
    const {t} = useTranslation();
    const error = useSelector(getErrorUserAdministration);
    const [requestError, setRequestError] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const dispatch = useDispatch();
    const company = useSelector(isEdit ? getCompanyEdit : getCompanyCreate);
    const { register, handleSubmit, formState: { errors }, reset } = useForm({ defaultValues: company });

    const cb = () => {
        closeModalWindow();
        setLoading(false);
    }

    const handleAddCompany = useCallback(data => {
        setLoading(true);
        isEmptyCompany(company)
            ? dispatch(addCompany(data, cb))
            : dispatch(editCompany({ data, isEdit }, cb));
    }, [dispatch, setLoading]);

    const closeModalWindow = () => {
        reset();
        dispatch(clearErrors('addCompany'));
        handleCloseDetails();
    }

    useEffect(() => {
        if (isLoading && requestError) setLoading(false);
    }, [isLoading, requestError]);

    useEffect(() => {
        setRequestError((error && error.find(er => er.method === 'addCompany')) || null);
    },[error]);

    const addCompanyForm = () => {
        return (
            <div className={'modal-container'}>
                <Form className={styles.add_form} onSubmit={handleSubmit(handleAddCompany)}>
                    <Grid container spacing={1}>
                        <Grid item xs={12} className={'mb-40 pt-0'}>
                            <Form.Group controlId="formBasicText" >
                            <Form.Label className={styles.label}>{t(`${name}.company_name`)}</Form.Label>
                            <Form.Control className={'input-modal'} type="text" {...register("name", { required: true, min: 1 })} />
                        </Form.Group>
                        </Grid>
                        <Grid item xs={9} className={'mb-40 pt-0'}>
                            <Form.Group controlId="formBasicText">
                                <Form.Label className={styles.label}>{t(`${name}.street`)}</Form.Label>
                                <Form.Control className={'input-modal'} type="text" {...register("street", { required: true, min: 1 })} />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={3} className={'mb-40 pt-0'}>
                            <Form.Group controlId="formBasicText">
                                <Form.Label className={styles.label}>{t(`${name}.number`)}</Form.Label>
                                <Form.Control className={'input-modal'} type="text" {...register("building", { required: true, min: 1 })} />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={5} className={'mb-40 pt-0'}>
                            <Form.Group controlId="formBasicText">
                                <Form.Label className={styles.label}>{t(`${name}.post_code`)}</Form.Label>
                                <Form.Control className={'input-modal'} type="text" {...register("zip", { required: true, min: 1 })} />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={7} className={'mb-40 pt-0'}>
                            <Form.Group controlId="formBasicText">
                                <Form.Label className={styles.label}>{t(`${name}.city`)}</Form.Label>
                                <Form.Control className={'input-modal'} type="text" {...register("city", { required: true, min: 1 })} />
                            </Form.Group>
                        </Grid>
                        <Grid item xs={12} className={'pt-0'}>
                            {
                                errors.company_name || errors.street || errors.building || errors.zip || errors.city
                                    ? <small className={styles.error_text}>Empty field</small>
                                    : requestError
                                    ? <small className={styles.error_text}>{requestError.message}</small>
                                    : null
                            }
                        </Grid>
                    </Grid>
                    <Row className={'justify-content-end'}>
                        <Button
                            variant="secondary"
                            type="button"
                            className={'btn-modal-secondary'}
                            onClick={() => closeModalWindow()}
                        >
                            {t(`${name}.cancel`)}
                        </Button>
                        <Button
                            variant="primary"
                            type="submit"
                            className={'btn-modal-primary ms-2'}
                            disabled={isLoading}
                        >
                            {isLoading ? 'LADEN..' : t(`${name}.add`)}
                        </Button>
                    </Row>
                </Form>
            </div>
        )
    }

    return useMemo(() => (
        <Modal boxId={'add_company_card_modal'}
               handleCloseDetails={closeModalWindow}
               open={open}
               header={isEmptyCompany(company) ? t(`${name}.header_add`) : t(`${name}.header_edit`)}
               classNameHeader = 'h5'
               hasCancelButton={false}
               classNameBox ={'secondary_box'}
        >
        { addCompanyForm() }
        </Modal>
    ), [open, addCompanyForm]);
}

export default AddCompanyCard;