import React, {useState, useEffect, useCallback, useRef} from "react";
import {FreeMode} from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { LazyLoadImage } from "react-lazy-load-image-component";

import {useTranslation} from "react-i18next";

import {getActiveImage, getCurrentProperty, getImgSupport, getShowHotspotImage} from "../../redux/selectors";
import {setActiveImage} from "../../redux/actions";
import {useSelector, useDispatch} from "react-redux";

import styles from "../../styles/components/Img360/Card360.module.css";
import {useParams} from "react-router";
import {housesRule} from "../../utils/constants";

const Card360 = () => {
    const { id: _propertyId } = useParams();
    const propertyId = _propertyId.split('&')[0];
    const currentProperty = useSelector(getCurrentProperty);
    const showCards = useSelector(getShowHotspotImage);
    const activeImage = useSelector(getActiveImage) || {};
    const defaultHouse = activeImage?.house || Object.keys(housesRule[propertyId])[0];
    const {images} = currentProperty || {};
    const [images360, setImages360] = useState(images);
    const [currentHouse, setCurrentHouse] = useState(defaultHouse === 'null' ? null : +defaultHouse);
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const didMountRef = useRef(false);
    const imgSupport = useSelector(getImgSupport);

    const changeCurrentHouse = useCallback(() => {
        dispatch(setActiveImage(images.find(image => image.name && image.roomId === 1 && image.house === currentHouse)));
    }, [images, currentHouse, dispatch]);

    useEffect(() => {
        setImages360(images.filter(image => image.house === currentHouse || image.house === null ));
        if (didMountRef.current) {
            changeCurrentHouse();
        }
        didMountRef.current = true;
    }, [currentHouse]);

    useEffect(() => {
        if (activeImage.house !== currentHouse) setCurrentHouse(activeImage.house);
    }, [activeImage]);

    const changeActiveImage = useCallback( image => {
        dispatch(setActiveImage(image));
    }, [dispatch]);

    return (
        <div className={`${!showCards ? styles.swiperContainer : styles.hiddenContainer}`}>
            {
                Object.keys(housesRule[propertyId])[0] !== 'null' ? (
                    <div className={styles.btnContainer}>
                            <button className={`${styles.selectBtn} ${(currentHouse === 5 ? styles.activeBtn : styles.unActiveBtn)}`} onClick={() => setCurrentHouse(5)}>{t('images360.house5')}</button>
                        <button className={`${styles.selectBtn} ${(currentHouse === 7 ? styles.activeBtn : styles.unActiveBtn)}`} onClick={() => setCurrentHouse(7)}>{t('images360.house7')}</button>
                    </div>
                ) : null
            }
            <Swiper freeMode modules={[FreeMode]} slidesPerView={4} spaceBetween={36} grabCursor>
                {
                    images.length > 1 && images360.map(image => (
                        image.name ? (
                            <SwiperSlide className={styles.card} key={image.id}>
                                <div className={`flex-column ${styles.cardContainer} ${(image.roomId === activeImage.roomId ? styles.active : styles.unActive)}`} onClick={() => changeActiveImage(image)}>
                                    <h3>{image.house ? `${t(`${image.name[0]}`)} - ${t(`${image.name[1]}`)}` : t(`${image.name[0]}`)}</h3>
                                    <LazyLoadImage alt="image 360" effect="blur" src={imgSupport ? image.imgWebp : image.imgPng}  className={styles.image360}/>
                                </div>
                            </SwiperSlide>
                        ) : null
                    ))
                }
            </Swiper>
        </div>
    )
};

export default Card360;
