export const getCompanyEditProperties = ({ userAdministration }) => userAdministration.template.edit.company.properties;
export const getCompanyCreateProperties = ({ userAdministration }) => userAdministration.template.create.company.properties;

export const getCompanyEditUsers = ({ userAdministration }) => userAdministration.template.edit.company.users;
export const getCompanyCreateUsers = ({ userAdministration }) => userAdministration.template.create.company.users;

export const selectCompaniesList = ({ userAdministration }) => userAdministration.companiesList;

export const getCompanyEdit = ({ userAdministration }) => userAdministration.template.edit.company;
export const getCompanyCreate = ({ userAdministration }) => userAdministration.template.create.company;


export const getErrorUserAdministration = ({ userAdministration }) => userAdministration.error;