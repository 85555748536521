import React, {useCallback, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { useForm } from "react-hook-form";
import styles from '../styles/components/Login.module.css'
import {Button, Form} from "react-bootstrap";
import {SignIn} from "../redux/actions";
import {useNavigate} from "react-router";
import {getError} from "../redux/selectors";
import {useDocumentTitle} from "../hooks";

const Login = props => {
  const { isAdmin = false } = props;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const error = useSelector(getError);
  const [requestError, setRequestError] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const onSubmit = useCallback(data => {
    setRequestError(false);
    setLoading(true);
    dispatch(SignIn({data, isAdmin}, () => {
      setLoading(false);
      navigate('/');
    } ))
  }, [dispatch, navigate, setLoading, setRequestError]);
  useDocumentTitle('Paul - Login');

  useEffect(() => {
    if (isLoading && requestError) setLoading(false);
  }, [isLoading, requestError]);

  useEffect(() => {
    setRequestError((error && error.find(er => er.method === 'SignIn')) || null);
  }, [error]);

  return (
    <div className={`container flex-center ${styles.login_form_wrap}`}>
      <Form className={styles.login_form} onSubmit={handleSubmit(onSubmit)}>
        <h2>Login</h2>
        <Form.Group className="mb-3" controlId="formBasicEmail">
          <Form.Label className={styles.label}>E-Mail-Adresse</Form.Label>
          <Form.Control type="text" {...register( "email", { required: true, min: 1, /* pattern: validationList.email */ })} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="formBasicPassword">
          <Form.Label className={styles.label}>Passwort</Form.Label>
          <Form.Control type="password" {...register("password", { required: true, min: 8 })} />
        </Form.Group>
        <Button
          variant="primary"
          type="submit"
          className="w-100 mb-20"
          disabled={isLoading}
        >
          {isLoading ? 'LADEN..' : 'LOGIN'}
        </Button>
        {(requestError || errors.email || errors.password) && <small className={styles.error_text}>Ungültiger login oder passwort</small>}
        <Form.Text className={`flex-center ${styles.help_links}`}>
          <a href="#">E-Mail vergessen?</a>
          <a href="#">Passwort vergessen?</a>
        </Form.Text>
      </Form>
    </div>
  )
};

export default Login;
