import { takeEvery, all } from 'redux-saga/effects';
import * as userTypes from '../types/user';
import * as userAdministrationTypes from '../types/userAdministration';
import * as propertiesTypes from '../types/properties';
import * as user from "./user";
import * as userAdministration from "./userAdministration";
import * as properties from "./properties";

export default function* appSagas(){
  yield all([
    yield takeEvery(userTypes.SIGN_IN, user.SignIn),
    yield takeEvery(userTypes.SIGN_OUT, user.SignOut),
    yield takeEvery(userTypes.CHECK_CONNECTION, user.checkConnection),

    yield takeEvery(propertiesTypes.GET_PROPERTIES, properties.getProperties),
    yield takeEvery(propertiesTypes.GET_PROPERTY, properties.getProperty),
    yield takeEvery(propertiesTypes.GET_CHART_DATA, properties._getChartData),

    yield takeEvery(userTypes.GET_PROFILE_INFO, user.GetProfileInfo),
    yield takeEvery(userTypes.UPDATE_PROFILE_INFO, user.UpdateProfileInfo),

    yield takeEvery(userAdministrationTypes.ADD_COMPANY, userAdministration.addCompany),
    yield takeEvery(userAdministrationTypes.EDIT_COMPANY, userAdministration.editCompany),
    yield takeEvery(userAdministrationTypes.GET_COMPANY, userAdministration.getCompany),
    yield takeEvery(userAdministrationTypes.GET_COMPANIES_LIST, userAdministration.getCompaniesList),
    yield takeEvery(userAdministrationTypes.ADD_COMPANY_PROPERTY, userAdministration.addCompanyProperty),
    yield takeEvery(userAdministrationTypes.REMOVE_COMPANY_PROPERTY, userAdministration.removeCompanyProperties),

    yield takeEvery(userAdministrationTypes.ADD_USER, userAdministration.addUser),
    yield takeEvery(userAdministrationTypes.UPDATE_COMPANY_USER, userAdministration.updateCompanyUser),
    yield takeEvery(userAdministrationTypes.SET_PASS_FOR_NEW_USER, userAdministration.setPassForNewUser),
    yield takeEvery(userAdministrationTypes.GET_CONFIRM_USER, userAdministration.getConfirmUser),
  ])
}
