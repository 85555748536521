import React, { useRef, useState } from 'react';
import Cropper from "react-cropper";
import {Button} from "react-bootstrap";

import {useDispatch} from "react-redux";

import "../../styles/components/Profile/Cropper.css";
import "cropperjs/dist/cropper.css";

import { UpdateProfileInfo } from '../../redux/actions';


const Crop = (props) => {
    const { avatar } = props;
    const cropperRef = useRef(null);
    const [cropper, setCropper] = useState();
    const dispatch = useDispatch();

    const getCropData = () => {
        if (cropper !== "undefined") {
            const file = {
                file: {
                    extension: "png",
                    data: cropper.getCroppedCanvas().toDataURL()
                }
            }
            dispatch(UpdateProfileInfo(file))
        }
    };

    return (
        <div className='flex-top-center flex-column cropper-box'>
            <Cropper
                style={{ height: "70vh", width: "60vw", marginTop: "30px", marginBottom: "20px" }}
                src={avatar}
                initialAspectRatio={1}
                aspectRatio={1}
                zoomTo={0.2}
                viewMode={1}
                autoCropArea={1}
                responsive={true}
                background={false}
                checkOrientation={false}
                guides={false}
                ref={cropperRef}
                onInitialized={(instance) => {
                    setCropper(instance);
                }}
            />
            <Button
                variant="primary"
                type="submit"
                onClick={getCropData}
            >
                Crop
            </Button>
        </div>
    );
};

export default Crop;