import React from 'react';
import styles from "../../styles/components/Profile/BottomInfo.module.css";
import {Grid} from "@mui/material";
import CompanyInfo from "./CompanyInfo";
import PersonalInfo from "./PersonalInfo";

const BottomInfo = props => {
    const { companyInfo, personalInfo } = props;

    return (
        <div className={styles.container}>
            <Grid container>
                <Grid item xs="auto">
                    <hr className={`${styles.divider} opacity-1`} />
                    <CompanyInfo companyInfo={companyInfo}/>
                </Grid>
                <Grid item xs="auto">
                    <hr className={`${styles.divider} ${styles.divider_container} opacity-1`} />
                    <PersonalInfo personalInfo={personalInfo}/>
                </Grid>
            </Grid>
        </div>
    );
};

export default BottomInfo;