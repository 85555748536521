import React, {useCallback} from 'react';
import {useTranslation} from "react-i18next";
import styles from "../../styles/components/Profile/CompanyInfo.module.css";
import {InputField} from "../Inputs";
import {Grid} from "@mui/material";
import {useDispatch, useSelector} from "react-redux";
import {UpdateProfileInfo} from "../../redux/actions";
import {getRole} from "../../redux/selectors";

const CompanyInfo = props => {
    const {companyInfo} = props;
    const {t} = useTranslation();
    const role = useSelector(getRole);
    const dispatch = useDispatch();

    const onChane = useCallback(v => dispatch(UpdateProfileInfo(v)), [dispatch]);

    return (
        <div className={styles.container}>
            <h5>{t("Profile.companyTitle")}</h5>
            <Grid container className={styles.field_container}>
                {
                    companyInfo.map(field =>
                        <Grid item xs={field.multiple ? 6 : 12} key={field.name}>
                            <InputField {...field} readOnly={role !== 'SUPER_ADMIN' || field.readOnly} key={field.name} onChange={onChane} />
                        </Grid>
                    )
                }
            </Grid>
        </div>
    );
};

export default CompanyInfo;