import React, {useCallback, useState} from 'react';
import {Button} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import {useTranslation} from "react-i18next";
import {DenseTable, UserSettingsItem} from "../index";
import {getCompanyCreateUsers, getCompanyEditUsers, getRole} from "../../redux/selectors";
import {useDispatch, useSelector} from "react-redux";
import {updateCompanyUser} from "../../redux/actions";
import AddUserCard from "./AddUserCard";

const UserSettings = props => {
    const name = 'UserSettings';
    const {t} = useTranslation();
    const { isEdit = false } = props;
    const role = useSelector(getRole)
    const users = useSelector(isEdit ? getCompanyEditUsers : getCompanyCreateUsers);
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const handleOpenModalAddUser = () => { if (!open) {setOpen(true)} }
    const handleCloseModalAddUser = () => setOpen(false);

    const updateUser = useCallback(user => {
        dispatch(updateCompanyUser({ user, isEdit }))
    }, [users, dispatch, isEdit])

    const AddButton = (
        <Button
            variant="contained"
            className={'btn-primary'}
            disableElevation
            endIcon={<AddIcon sx={{ width: 25, height: 25 }} />}
            onClick={handleOpenModalAddUser}
        >
            {t(`${name}.add`)}
        </Button>
    )

    return (
        <div>
            <div className={`flex-row flex-between mb-${users.length ? '70' : '20'}`}>
                <h2>{t(`${name}.header`)}</h2>
                {AddButton}
            </div>
            {users.length ? (
                <div className={'container-box-shadow bg-color-main-color-form-bg mb-140'}>
                    <DenseTable header={[
                        {text: ''},
                        {text: t(`${name}.tableHeaders.user`), align: 'left', style: {fontSize: '18px'}},
                        {text: t(`${name}.tableHeaders.email`), align: 'left', style: {fontSize: '18px'}},
                        {text: t(`${name}.tableHeaders.position`), align: 'left', style: {fontSize: '18px'}},
                        {text: t(`${name}.tableHeaders.role`), align: 'left', style: {fontSize: '18px'}},
                        {text: t(`${name}.tableHeaders.accessSince`), align: 'left', style: {fontSize: '18px'}},
                        {text: t(`${name}.tableHeaders.status`), align: 'left', style: {fontSize: '18px'}},
                        {text: t(`${name}.tableHeaders.settings`), align: 'left', style: {fontSize: '18px'}, showIf: role !== 'USER'},
                        // {text: t(`${name}.tableHeaders.settings`), align: 'left', style: {fontSize: '18px'}, showIf: role !== 'SUPER_ADMIN'},
                    ]}>
                        {users.map(user => <UserSettingsItem user={user} key={user._id} updateUser={updateUser} />)}
                    </DenseTable>
                </div>
            ) : null }
            <AddUserCard handleCloseDetails={handleCloseModalAddUser} open={open} isEdit={isEdit} />
        </div>
    )
}

export default UserSettings;