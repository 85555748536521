import building_1_webp from "./webp/DSC08859.webp";
import building_2_webp from "./webp/DSC08856.webp";
import building_def_webp from "./webp/4.webp";

import building_1_png from "./png/DSC08859.png";
import building_2_png from "./png/DSC08856.png";
import building_def_png from "./png/4.png";

const buildings = {
    webp: { building_1_webp, building_2_webp, building_def_webp },
    png: { building_1_png, building_2_png, building_def_png }
}

export { buildings };