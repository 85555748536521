import React, { useState, useEffect } from 'react';
import styles from '../../styles/components/Profile/ProfileImage.module.css';
import { Avatar } from '@mui/material';
import EditIconSVG from "../../icons/edit.svg";
import ProfileImgSVG from '../../icons/profile_image.svg'
import Modal from "../Modal";
import Crop from './Crop';
import { urlBack } from "../../utils/API";

import {getProfileImage} from "../../redux/selectors";
import {useSelector} from "react-redux";

import "cropperjs/dist/cropper.css";

const ProfileImage = () => {
    const [selectedImage, setSelectedImage] = useState();
    const [avatar, setAvatar] = useState(ProfileImgSVG);
    const croppedImage = useSelector(getProfileImage);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if(croppedImage) setAvatar(`${urlBack}${croppedImage}`);
        setOpen(false);
    }, [croppedImage])

    const onSelectImage = e => {
        if (!e.target.files || e.target.files.length === 0) {
            setSelectedImage(undefined)
            return
        }

        setSelectedImage(URL.createObjectURL(e.target.files[0]));
        setOpen(true);
    }

    const handleCloseDetails = () => setOpen(false)

    return (
        <div className={styles.container}>
            <label className={styles.label}>
                <input type="file" onChange={onSelectImage} onClick={ e => e.target.value = null }/>
                <Avatar alt="Avatar" src={avatar} className={styles.avatar} />
                <img src={EditIconSVG} alt="Edit img" className={styles.editImg}/>
            </label>
            <Modal open={open} className={styles.modalWindow} handleCloseDetails={!open ? handleCloseDetails : null}>
                <div className={styles.cropContainer}>
                    <Crop avatar={selectedImage}/>
                </div>
            </Modal>
        </div>
    );
};

export default ProfileImage;