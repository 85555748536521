import * as types from '../types/user';
import { getFromLocalStorage } from "../../utils/functions";

const initialState = {
  user: {
    token: getFromLocalStorage('token'),
    error: [],
    prototypeMode: getFromLocalStorage('prototypeMode'),
    image: '',
    // role: 'SUPER_ADMIN'
    role: ''
  },
};

export const user = (state = initialState.user, { type, payload }) => {
  switch (type) {
    case types.SIGN_IN_SUCCEEDED:
      return {
        ...state,
        token: payload.token,
        prototypeMode: payload.isPrototypeMode,
      };
    case types.SIGN_IN_FAILED:
      return {...state, error: [...state.error, { method: 'SignIn', ...payload }]};
    case types.REMOVE_AUTH:
      return {...state, token: null};
    case types.PROFILE_IMAGE:
      return {...state, image: payload};
    case types.GET_PROFILE_INFO_SUCCEEDED:
      return {...state, ...payload};
    case types.GET_PROFILE_INFO_FAILED:
      return {
        ...state,
        role: 'SUPER_ADMIN',
        error: [...state.error, { method: 'GetProfileInfo', ...payload }]
      };
    case types.UPDATE_PROFILE_INFO_SUCCEEDED:
      return {...state, ...payload};
    case types.UPDATE_PROFILE_INFO_FAILED:
      return {...state, error: [...state.error, { method: 'UpdateProfileInfo', ...payload }]};
    case types.CHECK_CONNECTION_FAILED:
      return {...state, error: [...state.error, { method: 'Connection', ...payload }]};
    default:
      return state;
  }
};
