import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Checkbox from '@mui/material/Checkbox';
import {Grid} from "@mui/material";
import {useTranslation} from "react-i18next";

import CheckOnSVG from '../../icons/check_on';
import CheckOffSVG from '../../icons/check_off';

const PipesInfoPanel = props => {
    const name = 'PipesInfoPanel';
    const { checked, setChecked, legend = [], setHoverTypeLine, pipes } = props;
    const { t } = useTranslation();

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];
        if (currentIndex === -1) {
            newChecked.push(value);
        } else newChecked.splice(currentIndex, 1);
        setChecked(newChecked);
    };
    return (
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 6 }} justifyContent="flex-start">
            <Grid item xs={6} md={6}>
                <p className={'color-main-color-primary'}>{t(`${name}.view`)}</p>
                <List dense sx={{ width: '100%', maxWidth: 600 }}>
                    {legend.map(item => {
                        const labelId = `checkbox-list-secondary-label-${item.id}`;
                        return (
                            <ListItem
                                key={item.id}
                                className={'listItem'}
                                onMouseEnter={() => setHoverTypeLine(item.id)}
                                onMouseLeave={() => setHoverTypeLine(null)}
                                secondaryAction={
                                    <Checkbox
                                        edge="end"
                                        disableRipple
                                        checked={checked.indexOf(item.id) !== -1}
                                        inputProps={{ 'aria-labelledby': labelId }}
                                        icon={CheckOffSVG}
                                        checkedIcon={CheckOnSVG}
                                    />
                                }
                                disablePadding
                            >
                                <ListItemButton onClick={handleToggle(item.id)} dense sx={{ zIndex: 1}}>
                                    {item.icon && (<i className={`line-chart-legend-${item.icon.type}`} style={item.icon.style}/>)}
                                    <ListItemText id={labelId} primary={t(`${name}.legend.${item.text[0]}`, {value: item.text[1] })} />
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                </List>
            </Grid>
            <Grid item xs={6} md={5}>
                <p className={'color-main-color-primary'}>{t(`${name}.information.header`)}</p>
                <List dense sx={{ width: '100%', maxWidth: 600 }}>
                        <ListItem
                            className={'listItem'}
                            secondaryAction={<ListItemText primary={`16. November 2021`} />}
                            disablePadding
                        >
                            <ListItemText primary={t(`${name}.information.inOperationSince`)} />
                        </ListItem>
                        <ListItem
                            className={'listItem'}
                            secondaryAction={<ListItemText primary={`${pipes}`} />}
                            disablePadding
                        >
                            <ListItemText primary={t(`${name}.information.numberOfHotWaterStrands`)} />
                        </ListItem>
                </List>
            </Grid>
        </Grid>
    )
}

export default PipesInfoPanel