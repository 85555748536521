import React, {useCallback, useState} from 'react';
import {Avatar, FormControl, MenuItem, Select, TableCell, TableRow} from "@mui/material";
import styles from '../../styles/components/UserAdministration/UserSettings.module.css'
import {useTranslation} from "react-i18next";
import {getDateFormat, getKeys, stringAvatar} from "../../utils/functions";
import {Roles, UserStatus} from "../../utils/constants";
import EditSVG from '../../icons/edit.svg';
import ButtonBase from '@mui/material/ButtonBase';
import {getRole} from "../../redux/selectors";
import {useSelector} from "react-redux";
import {urlBack} from "../../utils/API";
import { IOSSwitch } from "../Inputs";

const UserSettingsItem = props => {
    const name = 'UserSettings';
    const { user = {}, updateUser } = props;
    const {t} = useTranslation();
    const role = useSelector(getRole)
    const [roleSelectOpen, setRoleSelectOpen] = useState(false)

    const rowStyleFunc = e => {
        if (e.target.closest('ul')) return
        const target = e.target.classList.contains('MuiBackdrop-root') ? 'currentTarget' : 'target'
        return e[target]?.closest('tr')?.lastChild.style;
    }
    const rowIfWrap = (e, cb) => {
        const rowStyle = rowStyleFunc(e)
        if (rowStyle) cb(rowStyle)
    }

    const handleChangeRole = useCallback(e => {
        const { name, value } = e.target;
        updateUser({ ...user, [name]: value })
    }, [user, updateUser]);

    const rowClick = e => {
        if (e.target.tagName !== 'BUTTON') {
            rowIfWrap(e, rowStyle => rowStyle.opacity = 0)
        }
    }
    const editClick = e => rowIfWrap(e, rowStyle => {
            rowStyle.color = 'inherit';
            rowStyle.opacity = 1;
        })
    const switchUserClick = useCallback(e => {
        const enabled = e.target.checked;
        const enabledStatus = user.is_active ? 2 : 1;
        handleChangeRole({target: { name: 'status', value: enabled ? enabledStatus : 3 }})
        rowIfWrap(e, rowStyle => {
            rowStyle.color = enabled ? '#1CE783': '#D02B20';
            rowStyle.opacity = 1;
        })
    }, [handleChangeRole, user])

    const handleOpen = e => {
        editClick(e);
        setRoleSelectOpen(true);
    }
    const handleClose = () => setRoleSelectOpen(false);


    const avatar = user.image ? `${urlBack}${user.image }` : null

    return (
        <ButtonBase
            className={`${styles.table_content} user-select-auto`}
            key={user._id}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            component={TableRow}
            onMouseDown={rowClick}
        >
            <TableCell align="left">
                <Avatar alt="Avatar" src={avatar} {...(avatar ? {} : stringAvatar(`${user.first_name} ${user.last_name}`))} />
            </TableCell>
            <TableCell align="left">{`${user.first_name} ${user.last_name}`}</TableCell>
            <TableCell align="left"><span>{user.email}</span></TableCell>
            <TableCell align="left">{user.position || 'N/A'}</TableCell>
            <TableCell align="left">
                <FormControl fullWidth className={`flex-row ${styles.role}`} >
                    {role === 'USER' ? Roles[user.role] || '' : (
                        <ButtonBase variant="text" disableRipple onMouseDown={handleOpen}>
                           <Select
                               labelId="demo-simple-select-label"
                               id="demo-simple-select"
                               value={user.role || ''}
                               label="Age"
                               name='role'
                               className={styles.select}
                               open={roleSelectOpen}
                               onClose={handleClose}
                               onChange={handleChangeRole}
                           >
                               {getKeys(Roles).map(r => {
                                   if (r !== 'SUPER_ADMIN') {
                                       return <MenuItem key={r} value={r}>{Roles[r]}</MenuItem>
                                   }
                               })}
                           </Select>
                           <img src={EditSVG} className={'pointer-event-none'} alt={''} />
                       </ButtonBase>
                    )}
                </FormControl>
            </TableCell>
            <TableCell align="left">{getDateFormat(user.created_at)}</TableCell>
            <TableCell align="left">
                <div className={`flex-center ${styles.status} ${styles[UserStatus[user.status]]}`}>
                    {UserStatus[user.status] ? t(`${name}.status.${UserStatus[user.status]}`) : ''}
                </div>
            </TableCell>
            {role !== 'USER' &&
                <TableCell align="left">
                   <IOSSwitch sx={{ m: 1 }} checked={user.status !== 3 || false} onClick={switchUserClick} />
                </TableCell>
            }
        </ButtonBase>
    )
}

export default UserSettingsItem;