import React, {useCallback, useEffect, useState} from 'react';
import { PropertyCard } from "../index";
import {useSelector} from "react-redux";
import {
  getCompanyCreateProperties,
  getCompanyEditProperties,
  getDataProperties,
  getFilters
} from "../../redux/selectors";
import { FreeMode, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import {useFilterList} from "../../hooks";

const MapLayoutGrid = props => {
  const { nameForm, withChoice = false, isEdit = false } = props;
  const [cards, setCards] = useState([]);
  const data = useSelector(getDataProperties);
  const filters = useSelector(getFilters);
  const companyPropertiesIds = useSelector(isEdit ? getCompanyEditProperties : getCompanyCreateProperties) || [];
  const filter = filters[nameForm];

  const filteredCards = useFilterList({ nameForm, data });

  const getRestList = useCallback(() => {
    return filteredCards.filter(c => !(companyPropertiesIds.includes(c._id))) || []
  }, [companyPropertiesIds, filteredCards])

  const getCompanyProperties = useCallback(() => {
    return filteredCards.filter(c => companyPropertiesIds.includes(c._id))
  }, [companyPropertiesIds, filteredCards])

  useEffect(() => {
    if (withChoice) {
      setCards(getCompanyProperties().concat(getRestList()));
    } else setCards(filteredCards);
  }, [data, filter, filteredCards, withChoice, getCompanyProperties]);

  return (
    <Swiper freeMode modules={[FreeMode, Navigation]} slidesPerView={6} navigation grabCursor >
      {cards.map((card, i) => (
          <SwiperSlide key={card._id}>
            <PropertyCard card={card} show3D={false} transition={false} i={i} withChoice={withChoice} isEdit={isEdit} />
          </SwiperSlide>
      ))}
    </Swiper>
  )
};

export default MapLayoutGrid;
