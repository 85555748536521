import * as types from '../types/properties';
import {getStatus} from "../../utils/functions";
import {DetailsCharts} from "../../components"; // for fix error in rerender ??? Uncaught TypeError: Cannot read properties of undefined (reading 'default')

const initialState = {
  properties: {
    all: [],
    byId: {},
    filters: {
      properties: {},
    },
    active: null,
    activeSensor: [],
    chart: {
      data: {
          PAUL: {},
          Techem: {},
      },
      active: {
          PAUL: null,
          Techem: null,
      }
    },
    demoVersion: true
  },
};

export const properties = (state = initialState.properties, { type, payload }) => {
    switch (type) {
    case types.SET_PROPERTIES:
      return {...state, all: payload};
    case types.SET_PROPERTY: {
      const { _id } = payload[0];
      return {
        ...state,
        byId: {...state.byId, [_id]: payload[0]},
        all: state.all.length ? state.all.map(p => p._id === _id ? payload[0] : p) : [...payload],
      };
    }
    case types.SET_SENSOR: {
      const { property, _id, modbus } = payload;
      const mapSensors = p => p ? p.sensors.map(s => s._id === _id ? payload : s) : [];
      const hasId = state.byId[property];
      return {
        ...state,
        byId: {
          ...state.byId,
          [property]: {
            ...state.byId[property],
            sensors: hasId
              ? mapSensors(state.byId[property])
              : [{modbus, sensors: [payload], status: getStatus(payload, property)}],
            status: getStatus(mapSensors(state.byId[property]), property)
          }},
        all: state.all.map(p => p._id === property
          ? {...p, sensors: mapSensors(p), status: getStatus(mapSensors(p).sensors, property)}
          : p),
      };
    }
    case types.SET_ACTIVE_PROPERTY:
      const active = state.all.find(p => p._id === payload)
      return {...state, active: payload, byId: {...state.byId, [payload]: active }};
    case types.SET_ACTIVE_SENSOR:
      return {...state, activeSensor: payload};
    case types.ADD_ACTIVE_SENSOR:
      return {...state, activeSensor: [payload]};
    case types.REMOVE_ACTIVE_SENSOR:
        return {...state, activeSensor: []};
    case types.SET_FILTERS:
      return {...state, filters: {...state.filters, [payload.name]: payload.filter }};
    case types.GET_CHART_DATA_SUCCEEDED:
        return { ...state, chart: { ...state.chart, data: {...state.chart.data, [payload.type]: {...state.chart.data[payload.type], [payload.chartId]: payload.data} }}};
    case types.GET_CHART_DATA_FAILED:
        return { ...state };
    case types.SET_ACTIVE_CHART_DATA:
        return { ...state, chart: { ...state.chart, active: { ...state.chart.active, [payload.type]: payload.data }} };
    default:
      return state;
  }
};
