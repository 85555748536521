import React, {useContext, useEffect, useState} from 'react';
import Routing from './Routing';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/main.css';
import { HashRouter } from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  checkConnection,
  GetProfileInfo,
  getProperties,
  setImgSupport,
  setProfileImage
} from "./redux/actions";
import {connectSocket, disconnectSocket, SocketContext} from "./utils/socket-config";
import {getAuth} from "./redux/selectors";
import { useThemeDetector } from "./hooks";
import {checkWebPSupport} from "./utils/functions";
import "./utils/i18n";
import 'moment/locale/de'
import {useTranslation} from "react-i18next";
import moment from "moment";

const App = () => {
  const dispatch = useDispatch();
  const token = useSelector(getAuth);
  const socket = useContext(SocketContext);
  const isDarkTheme = useThemeDetector();
  const { i18n } = useTranslation();
  const [ isFirstEntry, setIsFirstEntry ] = useState(false);
  moment.locale(i18n.language);
  moment.updateLocale(i18n.language, { week: { dow: 1, doy: 7 }})

  useEffect(() => {
    if (token) {
      setIsFirstEntry(true);
      connectSocket();
      dispatch(checkConnection(token));
    } else if (!token && isFirstEntry) {
      disconnectSocket();
    }
  }, [socket, token, dispatch, isFirstEntry]);

  useEffect(() => {
    if (token && socket) {
      const interval = setInterval(() => {
        dispatch(checkConnection(token))
      }, 10000);
      dispatch(getProperties({token}));
      dispatch(GetProfileInfo())
      return () => {
        dispatch(setProfileImage())
        clearInterval(interval);
      }
    }
  }, [dispatch, token, socket]);

  useEffect(() => {
    return () => { if (!token) disconnectSocket()};
  },[]);

  useEffect(() => {
    if (isDarkTheme) {
      document.querySelector("link[rel='icon shortcut']").href = require('./images/favicons/favicon-32x32-Dark.png');
    } else {
      document.querySelector("link[rel='icon shortcut']").href = require('./images/favicons/favicon-32x32-Light.png');
    }
  }, [isDarkTheme]);

  useEffect(() => {
    checkWebPSupport().then((status) => {dispatch(setImgSupport(status))});
  }, [dispatch]);

  return (
    <HashRouter>
      <Routing />
    </HashRouter>
  )
};

export default App;