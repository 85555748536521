import React from 'react';
import {Box, Button} from "@mui/material";
import {Col, Row} from "react-bootstrap";
import styles from '../../styles/components/UserAdministration/CompanySettings.module.css'
import EditSVG from "../../icons/edit.svg";
import {getRole} from "../../redux/selectors";
import {useSelector} from "react-redux";

const CompanyInformation = props => {
    const name = 'CompanySettings';
    const { className = '', onClick, company = {} } = props;
    const role = useSelector(getRole);
    const { country = '', city = '', zip = '', street = '', building = '' } = company;
    return (
        <Row className={`container-box-shadow bg-color-main-color-form-bg ${styles.box} ${className}`}>
            <Col xs={10}>
                <h2 className={styles.title}>{company.name}</h2>
                <h2 className={'fw-normal'}>{street}{' '}{building}</h2>
                <h2 className={'fw-normal'}>{zip}{' '}{city}</h2>
            </Col>
            <Col xs={2} className={'flex-end'}>
                {role === 'SUPER_ADMIN' &&
                    (<>
                        {onClick ? (
                                <Button variant="text" disableRipple onClick={onClick}>
                                    <img src={EditSVG} className={'pointer-event-none'} alt={''} />
                                </Button>
                            ) : (
                                <Box className={`${styles.disabled_btn} flex-center`}>
                                    <img src={EditSVG} className={'pointer-event-none'} alt={''} />
                                </Box>
                        )}
                    </>)
                }
            </Col>
        </Row>
    )
}

export default CompanyInformation;