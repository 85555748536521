import React, {useCallback, useEffect, useState} from 'react';
import {ImageMap} from "@qiuz/react-image-map";
import styles from "../../styles/components/Performance/PerformanceImg.module.css";
import {getActiveSensor, getDataProperty, getFilterPerformance, getImgSupport} from "../../redux/selectors/index";
import {useDispatch, useSelector} from "react-redux";
import {addActiveSensor, setActiveSensor, removeActiveSensor} from "../../redux/actions";
import {useParams} from "react-router";
import {getPrototypeMode} from "../../redux/selectors";
import heater_webp from '../../images/performance/prototype/heater_on_2358_1651.webp';
import heater_png from '../../images/performance/prototype/heater_on_2358_1651.png';
import {useTranslation} from "react-i18next";

const PerformanceImg = props => {
    const dispatch = useDispatch();
    const {id: _id} = useParams();
    const id = _id.split('&')[0];
    const isPrototype = id === "62061f10452e21129b8ca242" || id === "62061f10452e21129b8ca243";
    const imgSupport = useSelector(getImgSupport);
    const prototypeMode = useSelector(getPrototypeMode);
    const activeSensor = useSelector(getActiveSensor);
    const {showTooltip, showTooltips, mapArea, filters, images} = props;
    const {hotWater, chilledWater, circulation, heatingLeadTime, heatingReturnFlow} = filters;

    const onMapClick = (area) => {
        if ((activeSensor.includes(area.id) || area.id.toString().includes('m')) && !area.className.includes('image-map__map')) {
            deleteActiveCard();
        }
        if (!area.id.toString().includes('m') && !activeSensor.includes(area.id)) {
            dispatch(addActiveSensor(area.id));
        }
    };

    const deleteActiveCard = () => dispatch(removeActiveSensor());


    return (
        <div className={[styles.usage_map_wrap + ' ' + (showTooltip ? '' : styles.hidden_tooltips)]}>
            <div className={styles.clickable_area} onClick={deleteActiveCard}/>
            <ImageMap className={styles.usage_map} src={images[0]} map={mapArea} onLoad={showTooltips}
                      onMapClick={onMapClick}/>
            {isPrototype && prototypeMode
                ? <img src={imgSupport ? heater_webp : heater_png}
                       className={[styles.img_layer + ' ' + (showTooltip ? '' : styles.hidden)]} alt=""
                       id="bg_heizkessel_on"/>
                : null}
            <img src={images[4]} alt="" className={[styles.img_layer + ' ' + (hotWater.show ? '' : styles.hidden)]}/>
            <img src={images[1]} alt=""
                 className={[styles.img_layer + ' ' + (chilledWater.show ? '' : styles.hidden)]}/>
            <img src={images[2]} alt=""
                 className={[styles.img_layer + ' ' + (heatingReturnFlow.show ? '' : styles.hidden)]}/>
            <img src={images[3]} alt=""
                 className={[styles.img_layer + ' ' + (heatingLeadTime.show ? '' : styles.hidden)]}/>
            <img src={images[5]} alt="" className={[styles.img_layer + ' ' + (circulation.show ? '' : styles.hidden)]}/>
        </div>
    )
};

const PerformanceImgWrap = props => {
    const dispatch = useDispatch();
    const filters = useSelector(getFilterPerformance);
    const [showTooltip, setShowTooltip] = useState(false);
    const data = useSelector(getDataProperty);
    const {mapArea, images} = props;
    const {t} = useTranslation();
    const active_sensors = useSelector(getActiveSensor);

    const [pointsData, setPointsData] = useState([]);

    const showTooltips = useCallback(() => {
        setShowTooltip(true);
    }, [setShowTooltip]);

    useEffect(() => {
        dispatch(setActiveSensor([]));
    }, [dispatch]);

    useEffect(() => {
        if (data.sensors) {
            const updateSensors = mapArea.map(point => {
                if (!point.id.toString().includes('m')) {
                    const p = data.sensors.find(s => s.modbus === point.id);
                    return {
                        ...point,
                        show: point.show ? point.show.toString() : null,
                        tooltip_text: point.tooltip_text ? t(point.tooltip_text[0], point.tooltip_text[1]) : '',
                        className: `${point.className} ${p && p.status ? '' : styles.status_bad}`,
                        status: p ? p.status.toString() : null,
                        sensors: p ? p.sensors : [],
                    };
                } else {
                    return {
                        ...point,
                        tooltip_text: point.tooltip_text ? t(point.tooltip_text[0], point.tooltip_text[1]) : '',
                    };
                }
            });
            setPointsData(updateSensors)
        }
    }, [data, filters, t, active_sensors, mapArea]);

    return (
        <PerformanceImg showTooltip={showTooltip} showTooltips={showTooltips} mapArea={pointsData} filters={filters}
                        images={images}/>
    )
};

export default PerformanceImgWrap;
