import React, {useCallback, useEffect, useState} from 'react';
import styles from '../../styles/components/MapPanel/PropertyCard.module.css'
import {propertyImgRule} from "../../utils/constants";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {useDispatch, useSelector} from "react-redux";
import {
  getActiveProperty, getCompanyCreate,
  getCompanyCreateProperties, getCompanyEdit,
  getCompanyEditProperties,
  getImgSupport, getRole
} from "../../redux/selectors";
import warningSVG from '../../icons/warning.svg'
import { buildings } from "../../images/buildings";
import {setActiveProperty} from "../../redux/actions";
import {Button} from "@mui/material";
import {useTranslation} from "react-i18next";
import {removeCompanyProperties, addCompanyProperties} from "../../redux/actions";

const PropertyCard = props => {
  const name = 'PropertyCard';
  const {t} = useTranslation();
  const role = useSelector(getRole)
  const { card, i, show = true, isForMap = false, goTo = null, withChoice = false, isEdit = false } = props;
  const { sensors = [] } = card;
  const hasWarning = sensors.find(s => !s.status);
  const [isSelected, setIsSelected] = useState(false);
  const imgSupport = useSelector(getImgSupport);
  const activeProperty = useSelector(getActiveProperty);
  const company = useSelector(isEdit ? getCompanyEdit : getCompanyCreate)
  const companyProperties = useSelector(isEdit ? getCompanyEditProperties : getCompanyCreateProperties);
  const dispatch = useDispatch();
  const isActive = activeProperty === card._id && !isForMap;

  useEffect(() => {
    if (withChoice) {
      setIsSelected(companyProperties.find(id => id === card._id))
    }
  }, [companyProperties, card, setIsSelected]);

  const disabled = role !== 'SUPER_ADMIN';

  const updateAssign = useCallback(() => {
    if(!disabled) {
      if (isSelected) {
        dispatch(removeCompanyProperties({ data: { _id: company._id, properties: company.properties.filter(id => id !== card._id) }, isEdit }))
      } else dispatch(addCompanyProperties({ data: { _id: company._id, properties: (company.properties || []).concat(card._id) }, isEdit }))
    }
  }, [isSelected, card, dispatch, company, disabled]);

  return (
    <button
      key={card._id}
      className={`${styles.container} ${isActive && !withChoice ? styles.active : ''} ${show ? '' : styles.hidden} ${withChoice && styles.withChoice}`}
      onClick={() => {
          if (!withChoice) {
              dispatch(setActiveProperty(card._id));
              if (goTo) goTo(card)
          }
      }}
    >
      <div className="flex-row flex-between">
        <b><span className="bold">0{i+1}</span></b>
        {hasWarning && !withChoice ? (
          <img src={warningSVG} alt="" className={styles.warning_icon} />
        ) : null}
      </div>
      <LazyLoadImage
        wrapperClassName={styles.lazy_load_image}
        alt="gebäude bild"
        effect="blur"
        src={propertyImgRule[card._id]
            ? (imgSupport ? propertyImgRule[card._id].imgWebp : propertyImgRule[card._id].imgPng)
            : (imgSupport ? buildings.webp.building_def_webp : buildings.png.building_def_png)}
        width={198}
      />
      <b><span className="bold">{card.name}</span></b>
      <span>{card.zip}{' '}{card.city}</span>
        {withChoice ? (
            <div className={'mt-3 flex-end'}>
                <Button
                    variant="contained"
                    className={`${isSelected ? 'btn-secondary' : 'btn-primary'} btn-m`}
                    disableElevation
                    disabled={disabled}
                    onClick={updateAssign}
                >
                    {t(`${name}.${isSelected ? 'unAssign' : 'assign'}`)}
                </Button>
            </div>
        ) : null}
    </button>
  )
};

export default PropertyCard;
