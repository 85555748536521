import React, {useCallback} from 'react';
import styles from '../../styles/components/DetailsCard/InformationDetails.module.css'

import ImageSlider from "../ImageSlider";
import {useTranslation} from "react-i18next";


const InformationDetails = props => {
    const name = 'InformationDetails';
    const { card, openFullSize } = props;
    const { info = null } = card.details || {};
    const { t } = useTranslation();

    const infoContent = useCallback((infoName) => {
        switch (infoName) {
            case 'tabs':
            case 'name': return null;
            case 'HeatGenerators': return { title: t(`${name}.HeatGenerators`), content: t(`${info.HeatGenerators}`) }
            case 'EnergySource': return { title: t(`${name}.EnergySource`), content: info.EnergySource }
            case 'NominalHeatOutput': return { title: t(`${name}.NominalHeatOutput`), content: info.NominalHeatOutput }
            case 'BoilerContents': return { title: t(`${name}.BoilerContents`), content: info.BoilerContents }
            case 'SerialNumber': return { title: t(`${name}.SerialNumber`), content: info.SerialNumber }
            case 'NominalDiameter': return { title: t(`${name}.NominalDiameter`), content: info.NominalDiameter }
            case 'PressureLoss': return { title: t(`${name}.PressureLoss`), content: info.PressureLoss }
            case 'Flow': return { title: t(`${name}.Flow`), content: info.Flow }
            case 'StorageType': return { title: t(`${name}.StorageType`), content: info.StorageType }
            case 'MemoryContent': return { title: t(`${name}.MemoryContent`), content: info.MemoryContent }
            case 'HeatOutput': return { title: t(`${name}.HeatOutput`), content: info.HeatOutput }
            case 'RotationalSpeed': return { title: t(`${name}.RotationalSpeed`), content: info.RotationalSpeed }
            case 'CurrentConsumption': return { title: t(`${name}.CurrentConsumption`), content: info.CurrentConsumption }
            case 'HeadOfFunding': return { title: t(`${name}.HeadOfFunding`), content: info.HeadOfFunding }
            case 'InstallationLength': return { title: t(`${name}.InstallationLength`), content: info.InstallationLength }
            case 'SafetyClass': return { title: t(`${name}.SafetyClass`), content: info.SafetyClass }
            case 'TypeOfRegulation': return { title: t(`${name}.TypeOfRegulation`), content: info.TypeOfRegulation }
            case 'OperatingHours': return { title: t(`${name}.OperatingHours`), content: info.OperatingHours }
            default : return null;
        }
    }, [t, info]);

    const infoContentRender = infoName => {
        const infoCont = infoContent(infoName);
        if (!infoCont) return null;
        const { title, content } = infoCont;
        return (
            <React.Fragment key={infoName}>
                <span>{title}</span>
                <span className={content === '?' ? styles.color_red : ''}>{content}</span>
            </React.Fragment>
        )
    }

    return (
        <div className={styles.container}>
            <div className={`${styles.img_wrap}`} >
                <ImageSlider card={card} openFullSize={openFullSize} />
            </div>
            {info ? (
                <div>
                    <h5>{info.name || ''}</h5>
                    <div className={styles.info}>
                        {Object.keys(info).map(infoContentRender)}
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default InformationDetails;