import * as types from '../types/user';

export const SignIn = (payload, cb) => ({ type: types.SIGN_IN, payload, cb });
export const SignOut = (payload, cb) => ({ type: types.SIGN_OUT, payload, cb });
export const RemoveAuth = (payload) => ({ type: types.REMOVE_AUTH, payload});

export const checkConnection = (payload) => ({ type: types.CHECK_CONNECTION, payload });

export const setProfileImage = (payload) => ({ type: types.PROFILE_IMAGE, payload });

export const GetProfileInfo = () => ({ type: types.GET_PROFILE_INFO });
export const UpdateProfileInfo = (payload) => ({ type: types.UPDATE_PROFILE_INFO, payload });