import React, {useEffect, useState} from 'react';
import styles from '../../styles/components/Sencors/SensorCard.module.css'
import { TemperatureCircle} from "../index";
import {getNameSensor} from "../../utils/functions";
import {exceptionsTemperatureRule, exceptionsTemperatureRuleNotModal} from "../../utils/constants";
import {useParams} from "react-router";
import {useTranslation} from "react-i18next";

const SensorCard = props => {
  const defaultTabType = 'PAUL'
  const { card = {}, isModal = false, warningFilter = false } = props;
  const { id: _propertyId } = useParams();
  const propertyId = _propertyId.split('&')[0];
  const { t } = useTranslation();
  const [tabType, setTabType] = useState(defaultTabType);
  let timer = null;

  const hideTime = e => {
    const timeBox = e.target.querySelectorAll('.time-box');
    if (timeBox) {
      timer = setTimeout(() => timeBox.forEach(t => t.classList.remove('time-box-for-hover')), 500);
    }
  };
  const showTime = e => {
    const timeBox = e.target.querySelectorAll('.time-box');
    if (timeBox) {
      clearTimeout(timer);
      timeBox.forEach(t => t.classList.add('time-box-for-hover'));
    }
  };

  const { sensors = [], modbus } = card || {};
  const { _id } = sensors[0] || {};

  const exception = modbus
      ? !isModal
        ? exceptionsTemperatureRuleNotModal[propertyId]?.includes(modbus)
        : exceptionsTemperatureRule[propertyId]?.includes(modbus)
      : false;

  const sensorName = sensors[0] ? getNameSensor(sensors[0] ? sensors[0] : null, t) || {} : {};

  useEffect(() => {
    if (warningFilter) {
      if (sensors[0].status !== null) {
        const statuses = sensors.map(sensor => !sensor.status.status);
        if (statuses.includes(false)) {
          const index = statuses.indexOf(true);
          setTabType( index !== -1 ? sensors[index].type : defaultTabType);
        } else {
          setTabType(defaultTabType);
        }
      } else {
        setTabType(sensors[0].type);
      }
    } else {
      setTabType(defaultTabType);
    }
  }, [warningFilter, sensors])

  return(
    <div
        className={`${styles.container} ${isModal ? styles.isModal : ''}  sensor-card`}
        key={_id}
        onMouseEnter={showTime}
        onMouseLeave={hideTime}
    >
      <div className={styles.name_wrap}>
        <p className={styles.name}>{sensors[0] ? sensorName.component : ''}</p>
        <p className={styles.name}>{sensors[0] ? sensorName.name : ''}</p>
      </div>
      <div>
        <TemperatureCircle sensors={sensors} type={exception ? sensors[0].type : tabType} />
      </div>
    </div>
  )
};

export default SensorCard;
