import * as types from '../types/userAdministration';

export const addCompanyProperties = (payload, cb) => ({ type: types.ADD_COMPANY_PROPERTY, payload, cb });
export const removeCompanyProperties = (payload, cb) => ({ type: types.REMOVE_COMPANY_PROPERTY, payload, cb });

export const updateCompanyUser = (payload, cb) => ({ type: types.UPDATE_COMPANY_USER, payload, cb });
export const setPassForNewUser = (payload, cb) => ({ type: types.SET_PASS_FOR_NEW_USER, payload, cb });
export const getConfirmUser = (payload, cb) => ({ type: types.GET_CONFIRM_USER, payload, cb });

export const addCompany = (payload, cb) => ({ type: types.ADD_COMPANY, payload, cb });
export const editCompany = (payload, cb) => ({ type: types.EDIT_COMPANY, payload, cb });

export const getCompany = (payload, cb) => ({ type: types.GET_COMPANY, payload, cb });
export const getCompaniesList = (payload, cb) => ({ type: types.GET_COMPANIES_LIST, payload, cb });

export const setTemplateEditCompany = (payload, cb) => ({ type: types.SET_TEMPLATE_EDIT_COMPANY, payload, cb });

export const addUser = (payload, cb) => ({ type: types.ADD_USER, payload, cb });

export const clearErrors = (payload, cb) => ({ type: types.CLEAR_ERRORS, payload, cb });