import { take, put, call } from 'redux-saga/effects'
import {API, sessionExpires, requestSuccess, requestError} from '../../utils/API';
import {RemoveAuth} from "../actions";
import {getFromLocalStorage, setToLocalStorage} from "../../utils/functions";
import {socketEmitter} from '../../utils/socket-config';

export function* SignIn({ type, payload, cb }){
  const { data, isAdmin } = payload;
  const dbType = isAdmin ? 'admins' : 'users';
  try {
    const resp = yield call(API.post, `${dbType}/login`, data);
    // const isPrototypeMode = data.username === 'Customer';
    const isPrototypeMode = true;
    yield put(requestSuccess(type, { token: resp.token, isPrototypeMode }));
    setToLocalStorage('token', resp.token);
    if (isPrototypeMode) setToLocalStorage('prototypeMode', true);
    if (cb) cb();
  } catch (error) {
    sessionExpires(error)
    yield put(requestError(type, { message: error.formError} ));
  }
}

export function* SignOut({ cb }){
  yield put(RemoveAuth());
  setToLocalStorage('token', null);
  setToLocalStorage('prototypeMode', false);
  if (cb) cb();
}

export function* checkConnection() {
  const token = getFromLocalStorage('token');
  const socketRequest = yield call(socketEmitter, {emit: 'ping', on: 'pong', payload: { token }})
  try {
    let resp = yield take(socketRequest)
    yield sessionExpires(resp)
  } catch (error) {
    sessionExpires(error)
    yield put(requestError('CONNECTION', { message: error.formError} ));
  }
}

export function* GetProfileInfo({ type }){
  try {
    const resp = yield call(API.get, 'users');
    yield put(requestSuccess(type, { ...resp }));
  } catch (error) {
    yield sessionExpires(error)
    yield put(requestError(type, { message: error.formError } ));
  }
}

export function* UpdateProfileInfo({ type, payload }){
  try {
    const resp = yield call(API.post, 'users', payload);
    yield put(requestSuccess(type, { ...resp }));
  } catch (error) {
    yield sessionExpires(error)
    yield put(requestError(type, { message: error.formError } ));
  }
}


