export const getDataProperties = ({properties}) => properties.all;
export const getDataProperty = ({properties}) => properties.byId[properties.active] || {};
export const getActiveProperty = ({properties}) => properties.active;
export const getActiveSensor = ({properties}) => properties.activeSensor;

export const getDemoVersion = ({properties}) => properties.demoVersion;

export const getFilters = ({properties}) => properties.filters;

export const selectChartData = ({properties}) => properties.chart.data;
export const getActiveChartData = ({properties}) => properties.chart.active;
