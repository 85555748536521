import React, {useCallback, useEffect, useRef, useState} from 'react';
import styles from '../../styles/components/MapView.module.css'
import 'mapbox-gl/dist/mapbox-gl.css';
import ReactMapboxGl from 'react-mapbox-gl';
import MapboxLanguage from '@mapbox/mapbox-gl-language';
import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router";
import {setActiveProperty, setZoom, setPitch} from "../../redux/actions";
import MapContent from "./MapContent";
import {getActiveProperty, getZoom, getPitch} from "../../redux/selectors";
import {useTranslation} from "react-i18next";

const MapDiv = ReactMapboxGl({
  container: 'map',
  zoom: 16.67,
  pitch: 70,
  bearing: -17.6,
  bearingSnap: 0,
  antialias: true,
  accessToken:
    'pk.eyJ1IjoicGF1bGdtYmgiLCJhIjoiY2t6Y3dnODg5MG12cTM0bzZsdnZsaHdybCJ9.KjtOa-eiZg8jeTcBKUEUbQ'
});

const defaultCenter = [8.285063970425599, 50.00791170829136];
const defaultPointers = [
  {coordinates: [8.124576182035836, 50.02926842822507], id: '62061f10452e21129b8ca241'},
  {coordinates: [8.285063970425599, 50.00791170829136], id: '62061f10452e21129b8ca242'},
  {coordinates: [8.285063970425599, 50.00791170829136], id: '62061f10452e21129b8ca243'}
];
const zoomThreshold = 16.68;

const MapView = props => {
  const { nameForm } = props;
  const [lastCenter, setLastCenter] = useState(defaultCenter);
  const DataRef = useRef({});
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [map, setMap] = useState();
  const { i18n } = useTranslation();
  const [mapboxLanguage] = useState(new MapboxLanguage({ defaultLanguage: i18n.resolvedLanguage || 'de' }));
  const activeProperty = useSelector(getActiveProperty);
  const [zoom3D, setZoom3D] = useState([11]);
  const isAutoZoom = useSelector(getZoom);
  const pitch = useSelector(getPitch);
  const [showCompass, setShowCompass] = useState(false);

  useEffect(() => {
    if (activeProperty) setTimeout(() => dispatch(setActiveProperty(null)), 1000);
    DataRef.current.disabledMap = false;
    return () => setLastCenter(defaultCenter);
  }, []);

  useEffect(() => {
    if (activeProperty) {
      const pointPosition = defaultPointers.find(point  => point.id === activeProperty);
      if (pointPosition) setLastCenter(pointPosition.coordinates);
    }
  }, [activeProperty]);

  useEffect(() => {
    if (map && i18n.resolvedLanguage && mapboxLanguage.setLanguage && map.getStyle())
      map.setStyle(mapboxLanguage.setLanguage(map.getStyle(), i18n.resolvedLanguage));
  }, [map, i18n.resolvedLanguage, mapboxLanguage]);

  const onStyleLoad = useCallback(m => {
    m.addControl(mapboxLanguage);
    setMap(m)
  }, [setMap, mapboxLanguage]);

  const autoShow3D = useCallback(() => {
    if (DataRef.current.isAutoZoom) {
      const currentZoom = map.transform.zoom;
      setZoom3D([currentZoom > 18 ? currentZoom + 2 : 18])
      DataRef.current.disabledMap = true;
      const goTo = () => {
        dispatch(setZoom(false));
        dispatch(setPitch(false));
        setTimeout(() => {
          navigate(`/property/details/${DataRef.current.property}`);
        }, 400);
        // setTimeout(() => setZoom3D([11]), 1000)
      }
      if (currentZoom === 20) {
        goTo()
      } else setTimeout(() => goTo(), 100)
    }
  }, [map, dispatch, navigate]);

  useEffect(() => { DataRef.current.property = activeProperty }, [activeProperty]);
  useEffect(() => { DataRef.current.isAutoZoom = isAutoZoom }, [isAutoZoom]);

  useEffect(() => {
    if (isAutoZoom) setZoom3D([16.69]);
  }, [isAutoZoom]);

  useEffect(() => {
    if (isAutoZoom && pitch) autoShow3D();
  }, [isAutoZoom, pitch, autoShow3D]);

  const setPitchFunc = m => {
    dispatch(setPitch(m.transform.pitch === 70));
  };

  const show3D = useCallback(m => {
    if ((m.getZoom() > zoomThreshold) && m.transform.pitch !== 70) {
      m.flyTo({ pitch: 70});
    } else if (m.transform.pitch !== 0 && m.getZoom() <= zoomThreshold)
      m.flyTo({ pitch: 0 });
  }, []);

  const onClick = () => {
    if (!DataRef.current.disabledMap) {
      dispatch(setActiveProperty(null))
      dispatch(setZoom(false));
    }
  }

  const onRotateStart = (_, event) => {
    if (event.originalEvent)
      setShowCompass(true);
     else
      setShowCompass(false);
  }

  useEffect(() => {
    if(activeProperty && map) {
      if(map.transform.pitch !== 70) {
        map.transform.pitch = 0;
      }
      map.flyTo({ center: lastCenter, essential: true, speed: 0.5})
    }
  }, [activeProperty])

  return (
    <div className={styles.map_wrap}>
      <MapDiv
        center={lastCenter}
        style="mapbox://styles/dinhnext/ckzffp6yb000r14mzosbce2f2"
        containerStyle={{ height: '100vh', width: '100vw' }}
        onStyleLoad={onStyleLoad}
        onZoom={show3D}
        onPitchEnd={setPitchFunc}
        zoom={zoom3D}
        onClick={onClick}
        onRotateStart={onRotateStart}
      >
        <MapContent nameForm={nameForm} defaultPointers={defaultPointers} map={map} showCompass={showCompass}/>
      </MapDiv>
    </div>
  )
};

export default MapView;
