import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styles from "../../styles/components/DetailsCard/DetailsCharts.module.css";
import {useTranslation} from "react-i18next";
import {useSelector} from "react-redux";
import {getActiveChartData, selectChartData} from "../../redux/selectors";
import moment from "moment";


const Container = ({ tIn, tOut, t, hasActiveData, isSingle }) => useMemo(() => {
    return (
        <div className={'flex-row flex-evenly text-center'}>
            {!isSingle ?
                (<div className={'flex-column'}>
                    <div className={'flex-left-center align-items-start'}>
                        <span className={styles.value}>
                            {(tIn - tOut).toFixed(0)}
                        </span>
                        <span>K</span>
                    </div>
                    <span>{'Delta'}<br/>{t('DetailsCharts.PreRun')}</span>
                </div>)
                : null}
            <div className={'flex-column'}>
                <div className={'flex-left-center align-items-start'}>
                    <span className={styles.value}>
                        {hasActiveData ? tIn.toFixed(0) : '-'}
                    </span>
                    <span>°C</span>
                </div>
                <span>
                    {t('DetailsCharts.DailyAverage')}<br/>
                    {t(isSingle ? 'DetailsCharts.temperature' : 'DetailsCharts.temperatureLeadTime')}
                </span>
            </div>
            {!isSingle ?
                (
                    <div className={'flex-column'}>
                        <div className={'flex-left-center align-items-start'}>
                            <span className={styles.value}>
                                {hasActiveData ? tOut.toFixed(0) : '-'}
                            </span>
                            <span>°C</span>
                        </div>
                        <span>{t('DetailsCharts.DailyAverage')}<br/>{t('DetailsCharts.temperatureReturn')}</span>
                    </div>
                )
                : null}
        </div>
    )
}, [ tIn, tOut, t, hasActiveData, isSingle ])

const InformationBottomPanel = props => {
    const { card = null, type = '' } = props;
    const { modbus = {} } = card || {};
    const { t } = useTranslation();
    const activeData = useSelector(getActiveChartData)[type]

    const [tIn, setTIn] = useState(0);
    const [tOut, setTOut] = useState(0);
    const [hasActiveData, setHasActiveData] = useState(false);
    const chartData = useSelector(selectChartData)
    const chartId = `chart_${card.id}_${type}`
    const isSingle = typeof modbus === 'number';

    useEffect(() => {
        if (activeData) setHasActiveData(true);
    }, [activeData])

    const getActiveDataByType = useCallback((mT) => {
        let modbusValue = isSingle ? modbus : modbus[mT];
        if (+activeData.rangeType === 1) {
            const { data = {} } = activeData.data.find(d => +d.serieId === modbusValue) || {};
            const time = data.x || null;
            if (time && chartData[type] && chartData[type][chartId]?.length) {
                const modbusData = chartData[type][chartId].reduce((acc, d) => ({...acc, ...d.modbuses}), {})[modbusValue]
                const dayArr = modbusData.filter(d => moment(d.date.split('T')[0]).isSame(time, 'day'))
                return {
                    x: time,
                    y: dayArr.reduce((acc, v) => acc + v.temperature, 0) / dayArr.length
                }
            } return {};
        } else {
            const { data = {} } = activeData.data.find(d => +d.serieId === modbusValue) || {};
            return data;
        }
    }, [activeData, modbus, type, chartData, isSingle, chartId]);

    const setT = useCallback(() => {
        const newSetTIn = getActiveDataByType('sensor_in').y || 0
        const newSetTOut = getActiveDataByType('sensor_out').y || 0
        if (newSetTIn !== tIn) setTIn(newSetTIn);
        if (newSetTOut !== tOut) setTOut(newSetTOut);
    }, [tIn, tOut, getActiveDataByType]);

    useEffect(() => {
        if (activeData) setT();
    }, [activeData, setT]);

    return <Container tIn={tIn} tOut={tOut} t={t} hasActiveData={hasActiveData} isSingle={isSingle} />
}

export default InformationBottomPanel;