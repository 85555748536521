import React from 'react';
import styles from '../../styles/components/Inputs/InputField.module.css';
import { DateField } from '../index';
import { useTranslation } from "react-i18next";

const InputField = props => {
  const { name, title, value, type = 'text', placeholder, readOnly = false, className = '', onChange } = props;
  const {t} = useTranslation();

  const updateField = e => {
    const text = e.currentTarget.textContent.trim();
    e.currentTarget.textContent = text;
    const newValue = { [name]: text }
    if (onChange) onChange(newValue);
  }

  const onInput = e => {
    if (e.currentTarget.textContent.trim().length === 0) {
      e.currentTarget.textContent = '';
    }
  }

  const handleKeyDown = (event) => {
      if (event.key === 'Enter') {
          updateField(event);
          event.target.blur();
      }
  }

  return (
    <div className={`${styles.fieldContainer} ${className}`}>
      <p className={styles.fieldTitle}>{t(title)}</p>
      {
        type === 'date' ? (
            <DateField date={value} fieldName={name} onChange={onChange} />
        ) : (
            <div className={!readOnly ? styles.icon : ''}>
              <div className={styles.input} placeholder={`${t(placeholder)} ${t("InputField.change")}`}
                   onBlur={updateField} onInput={onInput}
                   suppressContentEditableWarning={true} contentEditable={!readOnly}
                   onKeyDown={handleKeyDown}
                   readOnly={readOnly}
              >
                  {value}
              </div>
            </div>
        )
      }
    </div>
  )
};
export default InputField;
