import React, {useCallback, useEffect, useState} from 'react';
import FilterImg from '../icons/filter.svg'
import layoutGridOnImg from '../icons/layout-grid_on.svg'
import layoutGridOffImg from '../icons/layout-grid_off.svg'
import listOnImg from '../icons/list_on.svg'
import listOffImg from '../icons/list_off.svg'
import styles from '../styles/components/Filters.module.css'
import {Button} from "react-bootstrap";
import FormControl from "@mui/material/FormControl/FormControl";
import { Select, MenuItem } from '@mui/material';
import {useDispatch} from "react-redux";
import {setFilters} from "../redux/actions";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {useTranslation} from "react-i18next";

const SelectFilter = props => {
  const { filterList, updateSelector, updateActiveFilter, item } = props;
  const [open, setOpen] = useState(false);
  const { options = [], defaultValue = '' } = item;
  return (
      <Select
          value={filterList || ''}
          open={open}
          autoWidth
          onClose={() => setOpen(false)}
          onChange={e => {updateSelector(item, e.target.value)}}
          className={`selector ${filterList ? 'active' : ''}`}
          displayEmpty
          IconComponent={() => (
            <KeyboardArrowDownIcon
                className={`select_arrow ${open ? 'select_is_open' : ''}`}
                onClick={() => setOpen(!open)}
            />
          )}
          onOpen={e => {
            if (e.target.closest('button.selector_remove_btn')) {
              updateActiveFilter(item.value);
            } else setOpen(true);
          }}
          inputProps={{ 'aria-label': 'Without label' }}
      >
        <MenuItem value="" disabled hidden>{defaultValue}</MenuItem>
        {options.map((opt, i) => (
            <MenuItem key={i} value={opt.value}>
              <span>{opt.label}</span>
              {' '}
              <span className="ml-1 count">{opt.count ? `(${opt.count})` : ''}</span>
              <button type="button" className={`btn selector_remove_btn`}>
                <CloseIcon />
              </button>
            </MenuItem>
        ))}
      </Select>
  )
}

const Filters = props => {
  const { filterList = [], nameForm = '', typeDisplay, setTypeDisplay, showFilterIcon = true, className = '' } = props;
  const [activeFilters, saveActiveFilters] = useState({});
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(setFilters({ filter: activeFilters, name: nameForm }));
  }, [activeFilters, nameForm, dispatch]);

  const isIncludes = useCallback(v => Object.keys(activeFilters).includes(v), [activeFilters]);

  const updateSelector = useCallback((item, value) => {
    saveActiveFilters({ ...activeFilters, [item.value]: value });
  }, [activeFilters, saveActiveFilters]);

  const updateActiveFilter = useCallback(name => {
    if (isIncludes(name)) {
      saveActiveFilters(Object.keys(activeFilters).reduce((acc, n) => n !== name ? {...acc, [n]: activeFilters[n]} : acc, {}))
    } else {
      saveActiveFilters({ ...activeFilters, [name]: true });
    }
  }, [activeFilters, saveActiveFilters, isIncludes]);

  return (
    <div className={`flex-row flex-between ${className}`}>
      <div className={`${styles.filters_wrap} flex-row flex-top-center`}>
        {showFilterIcon ? <img src={FilterImg} alt="" className={styles.iconFilters}/> : null}
        {filterList.map(item => {
          const { showIf = true, type = null, id, value = '', text = '' } = item;
          const filterList = activeFilters[value];
          return type ? (
              <FormControl key={id} sx={{ minWidth: 120 }}>
                <SelectFilter  filterList={filterList} updateSelector={updateSelector} updateActiveFilter={updateActiveFilter} item={item} />
              </FormControl>
            ) : (
              <div
                key={id}
                className={[styles.filter_btn_wrap + ' '+ (showIf ? '' : styles.hide_filter)]}
              >
                <Button
                  variant="link"
                  className={[styles.filter_btn + ' '+ (isIncludes(value) ? styles.active_btn : '')]}
                  onClick={() => updateActiveFilter(value)}
                >
                  {text}
                </Button>
              </div>
            )
          }
        )}
        <Button
          variant="link"
          className={[styles.reset_btn + ' ' + (Object.keys(activeFilters).length ? '' : styles.hide)]}
          onClick={() => saveActiveFilters({})}
        >
          {t('Filters.reset')}
        </Button>
      </div>
      {typeDisplay ? (
        <div>
          <Button variant="link" className={'px-7'} onClick={() => setTypeDisplay('layout-grid')}>
            <img src={typeDisplay === 'layout-grid' ? layoutGridOnImg : layoutGridOffImg} alt='layout-Raster'/>
          </Button>
          <Button variant="link" className={'px-7'} onClick={() => setTypeDisplay('list')}>
            <img src={typeDisplay === 'list' ? listOnImg : listOffImg} alt='layout-Raster'/>
          </Button>
        </div>
      ) : null}
    </div>
  )
};

export default Filters;
