import React from 'react';
import styles from "../styles/components/Page.module.css";
import { Container } from "react-bootstrap";
import { BackButton } from "./Inputs";
import { Footer } from "./index";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
import { Box } from "@mui/material";

const Page = props => {
    const name = 'Page';
    const {t} = useTranslation();
    const navigate = useNavigate();
    const {
        backButton = {
            display: true,
            goBack: () => navigate(-1),
        },
        header = '',
        className = '',
        children
    } = props;

    return (
        <div className={styles.wrap}>
            {backButton.display ? (
                <Container fluid="lg" className={styles.container}>
                    <BackButton onClick={backButton.goBack} text={t(`${name}.BackButton`)} isButton isDark />
                </Container>
            ) : null}
            <Container fluid="md" className={styles.container_md}>
                <h1>{t(`${name}.header.${header}`)}</h1>
                <Box className={`${styles.page_container} ${className}`}>
                    {children}
                </Box>
                <Footer />
            </Container>
        </div>
    )
}

export default Page