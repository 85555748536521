import React, {useMemo} from 'react';
import { ResponsiveLine } from '@nivo/line';
import {
    _lineTheme,
    PredictionZone,
    getTimeFormat,
    getTimeFormatTitle,
    LineItem,
    getTickValues,
    EmptyZoneMesh
} from "./index";
import moment from "moment";

const DashedSolidLine = ({ series, ...props }) =>
    series.map(line => <LineItem {...props} line={line} key={line.id} />);

const LineChartPipes = ({ data, rangeType, dateType, minTemperature, checkedLegend, hoverTypeLine, isPrediction = false, t, from }) =>
    useMemo(() => (
        <div className="w-100 h-100 position-relative nivo-line-chart-separate-points">
            <ResponsiveLine
                data={data}
                theme={_lineTheme}
                margin={{ top: 35, right: 110, bottom: 60, left: 58 }}
                colors={{ datum: 'color' }}
                layers={[
                    'grid',
                    'axes',
                    'areas',
                    p => <PredictionZone {...p} isPrediction={isPrediction} t={t} />,
                    'markers',
                    'crosshair',
                    DashedSolidLine,
                    EmptyZoneMesh,
                    'points',
                    'slices',
                    'mesh',
                ]}
                markers={[
                    {
                        axis: "y",
                        value: minTemperature,
                        lineStyle: {
                            stroke: !!hoverTypeLine && hoverTypeLine !== 'minTemperature'
                                ? _lineTheme.disabledLine : "#1CE783",
                            strokeWidth: 2,
                            opacity: checkedLegend.includes('minTemperature') ? 1 : 0
                        },
                    },
                   {
                        axis: "x",
                        value: new Date(moment().hour(0).format('MM/DD/YYYY')),
                        lineStyle: {
                            stroke: "#0000FF",
                            strokeWidth: 2,
                            opacity: isPrediction ? 1 : 0
                        },
                       legend: t('lineChart.today'),
                       textStyle: {
                           transform: 'translate(-20px, -20px) rotate(0deg)',
                           fill: '#240bff',
                           opacity: isPrediction ? 1 : 0
                       }
                    }
                ]}
                tooltip={d => {
                    const { point = {} } = d;
                    const title = data.find(item => item.id === point.serieId)?.title || '';
                    return (
                        <div className={`flex-column line-chart-tooltip-value`}>
                            <span>{title}</span>
                            <span>{point.data ? `${point.data.y.toFixed(0)} °C` : '-'}</span>
                        </div>
                    )
                }}
                xFormat="time:%Y-%m-%d"
                xScale={{
                    type: 'time',
                    format: '%Y-%m-%d %H:%M',
                    useUTC: false,
                    precision: rangeType === 1 ? 'hour' : 'day',
                }}
                yFormat=" >-.1f"
                yScale={{
                    type: 'linear',
                    stacked: false,
                    reverse: false,
                    min: 'auto',
                    max: 'auto',
                }}
                maxY={100}
                curve="catmullRom"
                axisTop={{
                    format: t => getTimeFormatTitle(t, rangeType, dateType, from),
                    orient: 'bottom',
                    tickSize: 0,
                    tickPadding: 0,
                    tickRotation: 0,
                }}
                axisBottom={{
                    format: t => getTimeFormat(t, rangeType, dateType),
                    orient: 'bottom',
                    tickSize: 0,
                    tickPadding: 10,
                    tickRotation: 0,
                    tickValues: getTickValues({ dateType })
                }}
                axisLeft={{
                    format: value => `${value} °C`,
                    orient: 'left',
                    tickSize: 0,
                    tickPadding: 18,
                    tickRotation: 0,
                }}
                axisRight={null}
                enableGridX={false}
                lineWidth={1}
                enablePoints={false}
                areaOpacity={0.05}
                crosshairType="x"
                useMesh={true}
                motionConfig="stiff"
            />
        </div>
    ), [data, rangeType, dateType, checkedLegend.includes('minTemperature'), hoverTypeLine, isPrediction, t]);

export default LineChartPipes;