import React from 'react';
import styles from '../styles/components/PropertyOverview.module.css'
import { MapView, MapPanelCards } from "./index";

const PropertyOverview = () => {
  const nameForm = 'properties';

  return (
    <div className={styles.wrap}>
      <MapView nameForm={nameForm} />
      <MapPanelCards nameForm={nameForm} />
    </div>
  )
};

export default PropertyOverview;
