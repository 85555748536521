import {useEffect, useCallback, useState} from 'react'
import {useSelector} from "react-redux";
import {getFilters} from "../redux/selectors";

export const useFilterList = props => {
  const { nameForm = '', data = [] } = props;
  const filters = useSelector(getFilters);
  const filter = filters[nameForm];
  const [filteredList, setFilteredList] = useState([]);

  const setFilteredListFunc = useCallback(() => {
    const { display_warnings, city, zip } = filter || {};
    return data ? data.filter(card => {
      return !((display_warnings && isNaN(card.sensors.find(s => !s.status)))
          || (city && card.city !== city)
          || (zip && card.zip !== zip));
    }) : []
  }, [data, filter]);

  useEffect(() => {
    setFilteredList(setFilteredListFunc())
  }, [setFilteredListFunc, data, filter]);

  return filteredList;
};
